import { useAuthStore } from "../store/useAuth";

export default {
  install: async ({ config }: any, callback: any) => {
    config.globalProperties.$auth = useAuthStore();
    if (useAuthStore().isLoggedIn) {
      useAuthStore().fetchUser().then(() => callback() );
    }else{
      useAuthStore().setStaticToken().then(() => callback() );
    }
  },
};
