<template>
  <ion-header class="s-header main-public-header" :class="{'s-header-bg': !['landing', 'platinum', 'games'].includes(route) }">
    <ion-toolbar color="transparent">
      <ion-buttons slot="start" class="hide-md">
        <a :href="'/'" mode="ios" class="logo-btn">
          <img src="/assets/images/sharpicon.svg" slot="start" class="header-logo" alt="sharpicon.svg" />
        </a>
      </ion-buttons>
      <div slot="start" class="hide-sm header-left">
        <ion-row class="ion-align-items-center">
          <ion-col size="auto">
            <ion-label class="brand-name">
              <a :href="'/'">
                <img src="/assets/images/sharplogo.svg" alt="sharplogo.svg">
              </a>
            </ion-label>
          </ion-col>
          <!-- <ion-col>
            <ion-searchbar color="dark" mode="ios"></ion-searchbar>
          </ion-col> -->
        </ion-row>
      </div>
    </ion-toolbar>
      <!-- <game-switcher-component :route="route" /> -->
      <HeaderMenu/>
    <!-- <ion-toolbar color="transparent" style="width:fit-content;">
      <ion-buttons slot="end">
        <ion-button class="notifications-toggler" :id="'notifications_t_' + route" v-if="$auth?.isLoggedIn">
          <ion-icon color="gray" src="/assets/icons/notifications_filled.svg"></ion-icon>
          <ion-badge mode="ios" v-if="authStore.notificatiosCount" v-text="authStore.notificatiosCount"></ion-badge>
        </ion-button>
        <notifications-widget :route="route" v-if="$auth?.isLoggedIn" />

        <ion-button color="transparent" class="ion-no-padding" v-if="!$auth?.isLoggedIn" @click="goToLogin">
          <ion-avatar>
            <img src="/assets/images/placeholders/user.svg" />
          </ion-avatar>
        </ion-button>

        <ion-button color="transparent" class="ion-no-padding" :id="'user-menu_' + route" v-if="$auth?.isLoggedIn">
          <ion-avatar>
            <img v-if="$auth?.user?.avatar"
              :src="`${BASE_URL}/assets/${$auth.user.avatar}?fit=cover&width=100&height=100&quality=80`" />
            <img v-else src="/assets/images/placeholders/user.svg" />
          </ion-avatar>
        </ion-button>
        <ion-popover :trigger="'user-menu_' + route" v-if="$auth?.isLoggedIn" mode="md">
          <ion-content color="dark">
            <ion-list class="ion-no-padding" mode="md">
              <ion-item color="dark" button @click="logout()">
                <span>Logout</span>
              </ion-item>
            </ion-list>
          </ion-content>
        </ion-popover>

      </ion-buttons>
      <ion-buttons slot="end" class="ion-hide-lg-up">
        <ion-menu-button color="light"></ion-menu-button>
      </ion-buttons>
    </ion-toolbar> -->
  </ion-header>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
/* eslint-disable */
import {
  IonHeader,
  IonRow,
  IonCol,
  IonButtons,
  IonLabel,
  IonMenuButton,
  IonToolbar,
  // IonSearchbar,
  IonIcon,
  IonButton,
  IonAvatar,
  IonBadge,
  IonPopover,
  IonContent,
  IonList,
  IonItem,
} from "@ionic/vue";

import NotificationsWidget from "../../components/widgets/NotificationsWidget.vue";
// import GameSwitcherComponent from "./GameSwitcherComponent.vue";
import HeaderMenu from "./HeaderMenu.vue";
import { useAuthStore } from "@/store/useAuth";
import { BASE_URL } from '@/constants/api';
import router from "@/router";

export default defineComponent({
  name: "PageHeader",
  components: {
    IonHeader,
    IonRow,
    IonCol,
    IonButtons,
    IonLabel,
    IonMenuButton,
    IonToolbar,
    // IonSearchbar,
    IonIcon,
    IonButton,
    IonAvatar,
    IonBadge,
    IonPopover,
    IonContent,
    IonList,
    IonItem,
    NotificationsWidget,
    HeaderMenu
    // GameSwitcherComponent,
  },

  setup() {
    const route = computed(() => router.currentRoute.value.name?.toString() || '');
    const authStore = useAuthStore();

    const logout = async () => {
      await authStore.logout().catch(() => window.location.reload());
    }

    const goToLogin = () => {
      const currentRoute = router.currentRoute.value;
      const loginPath = '/login';

      if (currentRoute.path !== loginPath) {
        router.push({
          path: loginPath,
          query: {
            loginpath: currentRoute.fullPath
          }
        });
      }
    }


    return {
      route,
      logout,
      BASE_URL,
      authStore,
      goToLogin,
    };
  },
});
</script>
<style scoped lang="sass">
ion-header.s-header
  border-bottom: 0
  padding-right: var(--landing-horizontal-space)
  padding-left: var(--landing-horizontal-space)
  &.s-header-bg
    background-color: var(--ion-color-medium)

  &::after
    content: ''
    position: absolute
    bottom: -1px
    height: 1px
    display: block
    left: var(--landing-horizontal-space)
    width: calc(100% - 80px)
    background-color: #4B5661
    opacity: 0.38

  ion-toolbar
    margin-right: auto
    margin-left: auto


    @media (max-width: 600px)		 
        padding-right: var(--landing-horizontal-space-mobile)
        padding-left: var(--landing-horizontal-space-mobile)
  .platform-button .platform-link-badge
    top: 25px
    right: -22px

.header-left
  width: 100%

  ion-searchbar
    padding-bottom: 0
    margin-left: 20px
    --border-radius: 22px
    min-width: 250px
    margin-right: 20px

.brand-name
  font-weight: 600
  letter-spacing: 1px
  text-transform: uppercase
  font-size: 12px
  cursor: pointer
  display: flex
  height: 45px
  align-items: center
  a
    display: block

  img 
    height: 30px

ion-avatar
  width: 35px
  height: 35px

ion-button
  font-size: 18px
  height: auto

  &.s-game-link
    font-size: 28px
    position: relative
    --padding-top: 5px
    --padding-bottom: 5px

    &.active::after
      content: ""
      display: block
      position: absolute
      bottom: -3px
      width: 100%
      height: 3px
      background-color: var(--ion-color-success)

    &:not(:last-of-type)
      @media (min-width: 820px) 
        margin-right: 20px

    ion-icon
      transform: scale(0.85)


.notifications-toggler
  --overflow: visible
  --padding-top: 5px
  --padding-bottom: 5px
  ion-badge
    --background: #FF4842
    font-size: 10px
    --padding-start: 5px 
    --padding-end: 5px
    position: absolute
    bottom: 50%
    left: 50%

.bitmap-icon
  filter: brightness(0) invert(1)

ion-list
  background-color: var(--ion-color-dark)

.logo-btn
  --padding-start: 10px
  --padding-end: 10px

.header-logo
  height: 30px

.main-public-header
  display: flex
  border-bottom: 1px solid rgb(75, 86, 97)
  ion-toolbar
    width: 35%
    margin-left: 0px
    margin-right: 0px
</style>
