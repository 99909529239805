import { SharpPlatform } from "@/interfaces/sharpplatform";

export const sharpPlatforms = [
  {
    slug: "sorare",
    icon: "/assets/images/platforms/sorare.svg",
    name: "Sorare",
    sports: [
      {
        slug: "football",
        name: "Football",
        status: "active"
      },
      {
        slug: "nba",
        name: "NBA",
        status: "active"
      },
    ]
  },
  {
    slug: "ultimate-champions",
    icon: "/assets/images/platforms/ultimate-champions.svg",
    name: "Ultimate Champions",
    sports: [
      {
        slug: "football",
        name: "Football",
        status: "active"
      }
    ]
  },
  // {
  //   slug: "fpl",
  //   icon: "/assets/images/platforms/fpl2.png",
  //   name: "Fantasy Premier League",
  //   sports: [
  //     {
  //       slug: "football",
  //       name: "Football",
  //       status: "active"
  //     }
  //   ]
  // }
] as SharpPlatform[];

export const DEFAULT_PLATFORM = 'sorare';
export const DEFAULT_SPORT = 'football';

export const SUPPORTED_PLATFORMS = ['sorare', 'ultimate-champions', 'fpl'];
export const SUPPORTED_SPORTS = ['football', 'nba', 'basketball'];

export const PLATFORMS_DB_ORDER = ['sorare', 'ultimate-champions', '', 'fpl'];