<template>
  <ion-footer class="s-footer" :class="{'public-footer': isPublicLayout, 'force-show': forceShow}">
    <div>
      <ion-row :class="'ion-no-margin ion-justify-content-'+alignment">
        <ion-col size="12" size-md="auto" class="ion-no-margin ion-no-padding">
          <ion-button 
            mode="ios"
            size="small"
            fill="clear"
            class="ion-no-margin cky-banner-element"
          >
            <ion-icon src="/assets/icons/cookie.svg" color="warning"></ion-icon>
            <ion-text color="gray" class="s-m-start push-1px">
              {{ $t('footer.cookies') }}
            </ion-text>
          </ion-button>
        </ion-col>
        <ion-col size="4" size-md="auto" class="ion-no-margin ion-no-padding">
          <a 
            href="/terms-of-use"
            class="ion-no-margin footer-link"
            v-if="route === 'singlearticle'"
          >
            <ion-text color="gray">
              {{ $t('footer.terms') }}
            </ion-text>
          </a>
          <router-link 
            v-else
            to="/terms-of-use"
            class="ion-no-margin footer-link"
          >
            <ion-text color="gray">{{ $t('footer.terms') }}</ion-text>
          </router-link>
        </ion-col>
        <ion-col size="4" size-md="auto" class="ion-no-margin ion-no-padding">
          <a 
            href="/imprint"
            class="ion-no-margin footer-link"
            v-if="route === 'singlearticle'"
          >
            <ion-text color="gray">
              {{ $t('footer.imprint') }}
            </ion-text>
          </a>
          <router-link 
            v-else
            to="/imprint"
            class="ion-no-margin footer-link"
          >
            <ion-text color="gray">{{ $t('footer.imprint') }}</ion-text>
          </router-link>
        </ion-col>
        <ion-col size="4" size-md="auto" class="ion-no-margin ion-no-padding">
          <a 
            href="/privacy-policy"
            class="ion-no-margin footer-link"
            v-if="route === 'singlearticle'"
          >
            <ion-text color="gray">
              {{ $t('footer.privacy') }}
            </ion-text>
          </a>
          <router-link 
            v-else
            to="/privacy-policy"
            class="ion-no-margin footer-link"
          >
            <ion-text color="gray">{{ $t('footer.privacy') }}</ion-text>
          </router-link>
        </ion-col>
      </ion-row>
    </div>
  </ion-footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  IonFooter,
  IonButton,
  IonText,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/vue";
import { useRoute } from "vue-router";
import { APP_URL } from "@/constants/api";

export default defineComponent({
  name: "PageFooter",
  components: {
    IonFooter,
    IonButton,
    IonText,
    IonRow,
    IonCol,
    IonIcon,
  },

  props: {
    alignment: {
      required: false,
      type: String,
      default: 'end'
    },
    isPublicLayout: {
      required: false,
      type: Boolean,
      default: false
    },
    forceShow: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  setup(){
    const route = useRoute();

    return {
      APP_URL,
      route: route.name
    }
  }

});
</script>
<style scoped lang="sass">

ion-footer.s-footer
  @media screen and (min-width: 992px)
    margin-top: 30px
  box-shadow: none
  -webkit-box-shadow: none

  &:not(.force-show)
    @media screen and (max-width: 991px)
      display: none
    
  &.public-footer
    margin-top: 0
    >div
      margin-right: var(--landing-horizontal-space)
      margin-left: var(--landing-horizontal-space)
      padding-top: .5rem
      padding-bottom: .5rem

  &.footer-md::before
    display: none

  ion-row>ion-col
    display: flex
    align-items: center
    justify-content: center
    @media screen and (min-width: 992px)
      justify-content: flex-end
    padding-bottom: 0
    &:first-child
      @media screen and (max-width: 991px)
        margin-bottom: 10px

  .footer-link
    font-size: min(0.8125rem, 39px)
    padding: 4px .9em
    &:hover
      text-decoration: none
      opacity: .6

  .push-1px
    margin-top: 1px

</style>