export const CURRENCIES = [
  {
    name: 'eur',
    symbol: '€'
  },
  {
    name: 'gbp',
    symbol: '£'
  },
  {
    name: 'usd',
    symbol: '$'
  }
];