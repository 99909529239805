<template>
    <div style="display: flex; flex-direction: column; width:640px; min-height:326px;">
        <ion-row class="single-community-tool">
            <ion-col size="4">
                <ion-text>
                    <ion-icon src="/assets/icons/subscription.svg"></ion-icon>
                    Subscriptions</ion-text>
            </ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="7">
                <div class="tool-container">
                    <router-link 
                        size="4"
                         class="tool-item"
                         @click="goTo('/nba-subscription')"
                         :to="'/nba-subscription'"
                    >
                        <div class="tool-image">
                            <ion-icon src="/assets/images/sharpicon.svg"></ion-icon>
                        </div>
                        <span>
                            NBA Pass
                        </span>
                    </router-link>
                    <ion-col size="1"></ion-col>
                    <router-link 
                        size="4"
                        class="tool-item"
                        @click="goTo('/subscription')"
                        :to="'/subscription'"
                    >
                        <div class="tool-image">
                            <ion-icon src="/assets/images/sharpicon.svg"></ion-icon>
                        </div>
                        <span>
                            Supporter's Pass
                        </span>
                    </router-link>
                </div>
            </ion-col>
        </ion-row>

        <ion-row class="single-community-tool">
            <ion-col size="4">
                <ion-text>
                    <ion-icon src="/assets/icons/notifications_filled.svg"></ion-icon>
                    Socials</ion-text>
            </ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="7">
                <a href="https://twitter.com/Play_Sharper" class="single-social" target="_blank"
                    v-track-click="{ category: 'menu', action: 'open-socials', label: 'X' }">
                    <ion-icon src="/assets/icons/brand/twitter.svg"></ion-icon>
                    @Play_Sharper
                </a>

                <a href="https://t.me/playsharper" class="single-social" target="_blank"
                    v-track-click="{ category: 'menu', action: 'open-socials', label: 'Telegram' }">
                    <ion-icon src="/assets/icons/brand/telegram.svg"></ion-icon>
                    PlaySharper Telegram Community
                </a>

                <a href="https://www.youtube.com/@PlaySharper" class="single-social" target="_blank"
                    v-track-click="{ category: 'menu', action: 'open-socials', label: 'YouTube' }">
                    <ion-icon src="/assets/icons/brand/youtube.svg"></ion-icon>
                    @PlaySharper
                </a>
            </ion-col>
        </ion-row>

        <ion-row class="single-community-tool">
            <ion-col size="4">
                <ion-text>
                    <ion-icon src="/assets/icons/IoMail.svg"></ion-icon>
                    Newsletter</ion-text>
            </ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="7">
                <div class="community-newsletter-wrapper">
                    <ion-input v-model="email" type="email" autocomplete="email" enterkeyhint="done" inputmode="email"
                    placeholder="Enter your e-mail address"
                        v-track-click="{ category: 'menu', action:'tap-newsletter-input' }" />
                    <ion-button mode="ios" color="success" @click="subNewsletter(email)"
                        v-track-click="{ category: 'menu', action:'subscribe-newsletter' }">
                        Subscribe
                    </ion-button>
                </div>

            </ion-col>
        </ion-row>

        <ion-row class="single-community-tool">
            <ion-col size="4">
                <ion-text>
                    <ion-icon src="/assets/icons/playsharper_background.svg"></ion-icon>
                    PlaySharper App</ion-text>
            </ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="7" class="buy-app-wrapper">
                <a href="https://apps.apple.com/sa/app/playsharper/id1633083532" target="_blank"
                    v-track-click="{ category: 'menu', action:'download-app', label: 'ios' }">
                    <ion-img alt="app-store-buy.png" src="/assets/icons/app-store-buy.png"></ion-img>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.playsharper.app" target="_blank"
                    v-track-click="{ category: 'menu', action:'download-app', label: 'android' }">
                    <ion-img alt="google-play-badge.svg" src="/assets/icons/google-play-badge.svg"></ion-img>
                </a>
            </ion-col>
        </ion-row>


        <ion-toast :message="messageConfirm" position="top" color="success" :isOpen="showToast"
            css-class="toast-newsletter-top-right"></ion-toast>
        <ion-toast :message="messageConfirm" position="top" color="danger" :isOpen="showToastError"
            css-class="toast-newsletter-top-right"></ion-toast>
    </div>
</template>

<script lang="ts">
import { useAuthStore } from "@/store/useAuth";
import { IonRow, IonCol, IonText, IonImg, IonIcon, IonInput, IonButton, IonToast } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import router from '@/router';

export default defineComponent({
    components: {
        IonRow,
        IonCol,
        IonText,
        IonImg,
        IonIcon,
        IonInput,
        IonButton,
        IonToast
    },
    setup() {
        const email = ref('');
        const showToast = ref(false);
        const showToastError = ref(false);
        const messageConfirm = ref('');
        const authStore = useAuthStore();
        const subNewsletter = async (email: string) => {
            try {
                await authStore.subNewsletter(email);
                messageConfirm.value = 'Successfully subscribed to the Playsharper newsletter.';
                showToast.value = true;
                setTimeout(() => {
                    showToast.value = false;
                }, 3000);

            } catch (err) {
                const errorMessage = err.response && err.response.data && err.response.data.error
                    ? err.response.data.error
                    : 'Unknown error occurred. Please try again.';
                messageConfirm.value = errorMessage;
                showToastError.value = true;
                setTimeout(() => {
                    showToastError.value = false;
                }, 3000);

            }
        };

    
        const goTo = (url) =>{
            router.push({ path: url})

        }
        return {
            email,
            subNewsletter,
            messageConfirm,
            showToast,
            showToastError,
            goTo
        }
    }
})
</script>

<style lang="sass" scoped>
@media screen and (min-width: 1024px)
    .toast-newsletter-top-right 
        --start: settle-brown
        transform: translateY(30px) translateX(-50px) !important
        --min-width: 150px
        --display: flex
        --justify-content: center

.single-community-tool
    background: rgba(22, 28, 35, 0.60)
    border-radius: 16px
    padding: 24px
    &:not(:first-of-type)
        margin-top: 16px
    ion-col
        ion-text
            display: flex
            align-items: center
            line-height: 14px
            &:first-of-type
                ion-icon
                    width: 24px
                    height: 24px
                    margin-right: 10px
    &:nth-child(4), &:nth-child(3)
        display: flex
        align-items: center
    
        ion-img
            height: 46px
            cursor: pointer
            &:first-of-type
                margin-right: 10px
            &:last-of-type
                width: 150px
    .buy-app-wrapper
        display: flex
        align-items: center
.single-social
    margin: 0
    padding: 0
    font-size: 14px
    display: flex
    align-items: center
    color: #fff
    width: fit-content
    &:hover
        text-decoration: none
    &:not(:first-of-type)
        margin-top: 16px
    ion-icon
        width: 24px
        height: 24px
        margin-right: 10px

.community-newsletter-wrapper
    background-color: #161C23
    border-radius: 16px
    padding-left: 8px
    height: 56px
    display: flex
    align-items: center
    ion-input
        height: 56px
        max-width: 250px
    ion-button
        height: 60px

.tool-container
    display: flex
    flex-wrap: wrap


.tool-item 
  display: flex
  width: 98px
  height: 85px
  background-color: #1A212A
  border-radius: 8px
  padding: 8px
  font-size: 12px
  cursor: pointer
  display: flex
  flex-direction: column
  justify-content: space-between
  color: var(--ion-color-light)
  &:hover
    background-color: #1a212a47
    text-decoration: none

.tool-image
    width: 40px
    height: 40px
    border-radius: 8px
    background: linear-gradient(225deg, #662D91 0%, #10DFEF 100%)
    display: flex
    justify-content: center
    align-items: center
    ion-icon
        width: 24px
        height: 24px
</style>