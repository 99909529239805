import { useAuthStore } from "@/store/useAuth";

export const setSchema = async (type: string = 'general', data?: any) => {

    const authStore = useAuthStore();

    if (type === 'general') {
        const toBeRemoved = document.querySelectorAll('.schema-org:not(.schema-general)');
        toBeRemoved.forEach((el) => {
            el.remove();
        });

        const exists = document.querySelectorAll('.schema-general');

        if (!exists.length){
            const script = document.createElement('script');
            script.type = 'application/ld+json';
            script.className = 'schema-org schema-general';
            if (!authStore.schema) await authStore.getConfig();
            script.textContent = JSON.stringify(authStore.schema);
            document.head.appendChild(script);
        }
    }
    else{
        if (type === 'games' && data){
            const toBeRemoved = document.querySelectorAll('.schema-general, .schema-games, .schema-singlearticle');
            toBeRemoved.forEach((el) => {
                el.remove();
            });
        }
    
        else if(type === 'singlearticle' && data){
            const toBeRemoved = document.querySelectorAll('.schema-general, .schema-games, .schema-singlearticle');
            toBeRemoved.forEach((el) => {
                el.remove();
            });
        }
    
        else if (type === 'discussion' && data){
            const toBeRemoved = document.querySelectorAll('.schema-discussion');
            toBeRemoved.forEach((el) => {
                el.remove();
            });
        }

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.className = `schema-org schema-${type}`;
        script.textContent = JSON.stringify(data);
        document.head.appendChild(script);

    }
}