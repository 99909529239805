<template>
  <ion-row class="header-tabs-wrapper">
    <div class="menu-items">

      <div v-for="tab in tabs" :key="tab.value" class="menu-item"
        :class="{ 'menu-item-checked menu-item-hover': activeTab === tab.value || openedTab === tab.value }">
        <router-link v-if="tab.url" @mouseenter="hoverEnter(tab.value)" @mouseleave="hoverLeave" :to="tab.url || ''">
          <div>
            <ion-label>
              {{ tab.label }}
            </ion-label>
            <ion-icon v-if="tab.icon" class="arrow-icon" :src="tab.icon"></ion-icon>
          </div>
          <span class="active-border"></span>
        </router-link>

        <div v-else @mouseenter="hoverEnter(tab.value)" @mouseleave="hoverLeave" @click="navigate(tab, $event)">
          <div>
            <ion-label>
              {{ tab.label }}
              <ion-icon src="/assets/icons/beta.svg" v-if="tab.value === 'home'" class="beta-icon"></ion-icon>
            </ion-label>
            <ion-icon v-if="tab.icon" class="arrow-icon" :src="tab.icon"></ion-icon>
          </div>
          <span class="active-border"></span>
        </div>
      </div>
      
      <ion-row class="header-profile-wwrapper">
        <ion-buttons>
          <ion-button class="notifications-toggler" :id="'notifications_t_' + route" v-if="$auth?.isLoggedIn">
            <ion-icon color="gray" src="/assets/icons/notifications_filled.svg"></ion-icon>
            <ion-badge mode="ios" v-if="authStore.notificatiosCount">{{ authStore.notificatiosCount }}</ion-badge>
          </ion-button>
          <notifications-widget :route="route" v-if="$auth?.isLoggedIn" />

          <ion-button color="success" fill="solid" shape="round" class="header-login" v-if="!$auth?.isLoggedIn"
            @click="goToLogin">
            Login/Register
          </ion-button>
        </ion-buttons>
        <a class="menu-item" value="auth" v-if="$auth?.isLoggedIn" style="margin-left:0px;"
          :class="{ 'menu-item-checked menu-item-hover': openedTab === 'auth' }" @mouseenter="hoverEnter('auth')"
          @mouseleave="hoverLeave" @click.stop="">
          <div>
            <ion-label class="header-avatar">
              <ion-button color="transparent" class="ion-no-padding" :id="'user-menu_' + route" fill="clear">
                <ion-avatar>
                  <img v-if="$auth?.user?.avatar" :alt="$auth.user.avatar"
                    :src="`${BASE_URL}/assets/${$auth.user.avatar}?fit=cover&width=100&height=100&quality=80`" />
                  <img v-else src="/assets/images/placeholders/user.svg" alt="user.svg" />
                </ion-avatar>
              </ion-button>
            </ion-label>
            <ion-icon class="arrow-icon" src="/assets/icons/expand_less.svg"></ion-icon>
          </div>
          <span class="active-border"></span>
        </a>
      </ion-row>
    </div>
    <ion-row class="content-wrapper" v-show="openedTab && openedTab !== 'articles' && openedTab !== 'home'"
      :style="{ top: contentTop + 'px', left: contentLeft + 'px' }" @mouseenter="contentEnter" @mouseleave="contentLeave">
      <div v-if="openedTab === 'tools'">
        <ToolsTab />
      </div>
      <div v-if="openedTab === 'community'">
        <CommunityTab />
      </div>
      <div v-if="openedTab === 'auth'">
        <ProfileTab />
      </div>

    </ion-row>
  </ion-row>
</template>

<script lang="ts">
import ProfileTab from '../header/ProfileTab.vue'
import ToolsTab from '../header/ToolsTab.vue'
import CommunityTab from '../header/CommunityTab.vue'
import {
  IonLabel,
  IonIcon,
  IonButton,
  IonRow,
  IonButtons,
  IonAvatar,
  IonBadge,
} from '@ionic/vue';
import { ref } from 'vue';
import router from '@/router';
import NotificationsWidget from "../../components/widgets/NotificationsWidget.vue";
import { useAuthStore } from "@/store/useAuth";
import { BASE_URL } from '@/constants/api';
import { onMounted } from 'vue';
export default {
  components: {
    IonLabel,
    IonIcon,
    IonButton,
    NotificationsWidget, IonRow,
    IonButtons,
    IonAvatar,
    IonBadge,
    ProfileTab,
    ToolsTab,
    CommunityTab
  },
  setup() {
    const tabs = [
      { label: 'Games', value: 'home', url: '/' },
      { label: 'Tools', value: 'tools', icon: '/assets/icons/expand_less.svg' },
      { label: 'Articles', value: 'articles', url: '/articles' },
      { label: 'More', value: 'community', icon: '/assets/icons/expand_less.svg' },
    ];

    const route = router.currentRoute.value.name?.toString() || '';
    const authStore = useAuthStore();

    const goToLogin = () => {
      const currentRoute = router.currentRoute.value;
      const loginPath = '/login';

      if (currentRoute.path !== loginPath) {
        router.push({
          path: loginPath,
          query: {
            loginpath: currentRoute.fullPath
          }
        });
      }
    }

    const activeTab = ref(null);
    const openedTab = ref(null);
    const contentTop = ref(0);
    const contentLeft = ref(0);
    const hoverTimeout = ref(null);

    const clearHoverTimeout = () => {
      if (hoverTimeout.value) {
        clearTimeout(hoverTimeout.value);
        hoverTimeout.value = null;
      }
    };

    const hoverEnter = (value) => {
      clearHoverTimeout();
      openedTab.value = value;
      const segmentButton = (event.target as Element).closest('.menu-item');
      if (segmentButton) {
        const rect = segmentButton.getBoundingClientRect();
        contentTop.value = rect.bottom + window.scrollY;
        if (openedTab.value == 'auth') {
          contentLeft.value = rect.left + window.scrollX - 110
        }
        else if (openedTab.value == 'tools') {
          contentLeft.value = rect.left + window.scrollX - 100
        }

        else if (openedTab.value == 'community') {
          contentLeft.value = rect.left + window.scrollX - 380
        }
      }
    };

    const hoverLeave = () => {
      hoverTimeout.value = setTimeout(() => {
        openedTab.value = null;
        hoverTimeout.value = null;
      }, 300);
    };

    const contentEnter = () => {
      clearHoverTimeout();
    };

    const contentLeave = () => {
      openedTab.value = null;
    };



    const goTo = (url) => {
      router.push(url)
    }

    const navigate = (tab, event) => {
      if (tab.url) {
        event.preventDefault();
      }
    }

    const setTabByRoute = (group: string) => {
      switch (group) {
        case 'home':
          activeTab.value = 'home'
          break;
        case 'games':
          activeTab.value = 'home'
          break;
        case 'articles':
          activeTab.value = 'articles'
          break;
        case 'tools':
          activeTab.value = 'tools'
          break;
        case 'more':
          activeTab.value = 'community'
          break;
        default:
          activeTab.value = ''
          break;
      }
    }

    onMounted(() => {
      const current = router.currentRoute.value.meta.group;
      setTabByRoute(current.toString());
      router.afterEach((to) => {
        setTabByRoute(to.meta.group.toString());
      });
    })

    return {
      tabs, activeTab, openedTab, contentTop, contentLeft, hoverEnter, hoverLeave, contentEnter, contentLeave,
      goToLogin, route, authStore, BASE_URL, goTo, navigate
    };
  },
};
</script>

<style lang="sass" scoped>
.header-tabs-wrapper
  display: flex
  width: 65%
  justify-content: space-between
  >.menu-items
    display: flex
    width: 100%
    a, div
      cursor: pointer
      text-decoration: none
  .header-profile-wwrapper
    margin-left: auto
    display: flex

  .content-wrapper
    position: absolute
    margin: 0
    z-index: 100
    border: 1px solid #00AB55
    background: linear-gradient(0deg, rgba(26, 33, 42, 0.40) 0%, rgba(26, 33, 42, 0.40) 100%), rgba(40, 47, 63, 0.80)
    box-shadow: 0px 5px 20px 0px rgba(22, 28, 35, 0.64)
    backdrop-filter: blur(10px)
    padding: 24px
    border-radius: 16px

  .menu-item
    max-width: fit-content
    position: relative
    margin: 0px 5px
    padding-right: 12px
    padding-left: 12px
    text-align: center
    font-weight: 500
    font-size: 14px
    .header-avatar
      ion-button
        width: 32px
        height: 32px
        margin: 0
      ion-avatar
        width: 32px
        height: 32px
    &:hover, &.menu-item-hover
      background-color: rgba(var(--ion-color-primary-rgb), .04)
      text-decoration: none
      .active-border
        height: 4px
        display: flex
        position: absolute
        bottom: 0px
        left: 16px
        width: 100%
        width: calc(100% - 32px)
        background: #00AB55
        border-top-left-radius: 5px
        border-top-right-radius: 5px
      ion-label
        color: #00AB55
        transition: color 200ms ease-in

      ion-icon:not(.beta-icon)
        color: #00AB55
        transition: color 200ms ease-in
        transform: rotate(0deg)
        transition: transform 300ms ease-in-out

    div
      display: flex
      align-items: center
      padding: 0px 10px
      position: relative
      height: 100%

    ion-label
      color: #E4ECFF
      text-transform: none
    ion-icon:not(.beta-icon)
      color: #E4ECFF
      margin-left: 8px
      transform: rotate(180deg)
      transition: transform 300ms ease-in-out

    ion-icon.beta-icon
      position: absolute
      font-size: 18px
      right: -20px
      bottom: 5px
      width: 40px

.header-login
  font-size: 0.8em
  --padding-start: 15px
  --padding-end: 15px
</style>
