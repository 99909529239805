<template>
  <ion-split-pane content-id="main-content">
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/vue";

export default defineComponent({
  name: "mainLayout",
  components: {
    IonRouterOutlet,
    IonSplitPane,
  },
});
</script>