<template>
  <ion-app>
    <PublicHeader v-if="!isMobile()" />
    <MobileHeader v-if="isMobile()" />
    <MobileMenu v-if="isMobile()" />
    <component :is="$route.meta.layout ? $route.meta.layout : 'main'">
      <router-view v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </component>

    <GoogleTagManager />
    <Hotjar />
    <SchemaOrg />

  </ion-app>

</template>

<script lang="ts">
import PublicHeader from "./components/shared/PublicHeader.vue";
import MobileHeader from "./components/shared/MobileHeader.vue";
import MobileMenu from "./components/shared/MobileMenu.vue";
import SchemaOrg from "./components/shared/SchemaOrg.vue";
import { defineComponent, ref } from "vue";
import { 
  IonApp, 
  IonModal, 
} from "@ionic/vue";
import { StatusBar, Style } from "@capacitor/status-bar";
import GoogleTagManager from "@/components/shared/GoogleTagManager.vue";
import Hotjar from "@/components/shared/Hotjar.vue";

import {
  ActionPerformed,
  PushNotifications,  
  Token,
} from '@capacitor/push-notifications';
import { FCM } from "@capacitor-community/fcm";
import { GoogleAnalytics } from "@awesome-cordova-plugins/google-analytics";
import { useRouter } from "vue-router";
import { App, URLOpenListenerEvent } from '@capacitor/app';
import router from "./router";
import { useAuthStore } from "./store/useAuth";
import { isApp, isMobile } from "./services/app";
import { computed } from "vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonModal,
    StatusBar,
    GoogleTagManager,
    PublicHeader,
    Hotjar,
    MobileHeader,
    MobileMenu,
    SchemaOrg,
  },

  mounted: () =>{
    App.addListener('appUrlOpen', function (data: URLOpenListenerEvent) {
      const urlArr = data.url.split('playsharper.com');
      const slug = urlArr.length > 1 ? urlArr[1] : '/404';
      router.push(slug);
    })
  },

  setup() {
    const router = useRouter();
    const authstore = useAuthStore();
    const randomId = ref(Math.random().toString(36).substring(7));

    const setStatusBarStyleLight = async () => {
      await StatusBar.setStyle({ style: Style.Dark });
    }

    const startAnalyticsTracking = async () => {
      GoogleAnalytics.startTrackerWithId("G-H1C7LC9Y4F")
        .then(() => {
          console.log("tracking established");
        })
        .catch((err: any) => {
          console.warn(err);
        });
    };

    const currentRoute = computed(() => router.currentRoute.value.name.toString());

    const initPushNotificationsPermission = async () => {
      await PushNotifications.requestPermissions().then(async result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          await PushNotifications.register();
          initNotifications();
        } else {
          // Show some error
          console.log('error token permissions');
        }
      });
    };

    const initNotifications = async () => {

      await PushNotifications.addListener('registration',
        async (token: Token) => {
          await FCM.getToken();
          authstore.userPhoneToken = token.value;
        });

      PushNotifications.addListener('registrationError',
        (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        }
      );
      PushNotifications.addListener(
        'pushNotificationReceived',
        () => {
          authstore.getNotifications();
        },
      );

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          if(notification.notification.data.link){
            router.push(notification.notification.data.link);
          }
          // alert(JSON.stringify(notification));
        },
      );
    }

    if (isApp) {
      setStatusBarStyleLight();
      startAnalyticsTracking();
      initPushNotificationsPermission();
    }

    authstore.getAllAds(isMobile() ? 'mobile' : 'desktop');

    return {
      isMobile,
      randomId,
      currentRoute,
    }

  },
  
});
</script>

<style lang="sass" scoped>
main
  .ion-page
    padding-top: 54px

.main-component
  padding-top: 64px
  @media screen and (max-width: 600px)
    margin-top: 59px !important
    padding-top: 0px !important
    margin-bottom: 70px !important

.show-support-popup
  --backdrop-opacity: .8
  --height: auto
  --width: 500px
</style>