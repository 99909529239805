<template>
    <ion-row class="tools-items">
        <div class="tools-platforms" v-if="!selectedPlatform">
           
            <ion-row v-for="platform in authStore.sharpPlatforms" :key="platform.slug" @click="selectPlatform(platform)"
                class="single-platform-tools" v-track-click="{ category: 'menu', action:'open-platform', label: platform.slug }">
                <ion-img :alt="platform.icon" :src="platform.icon"></ion-img>
                <div>
                    <ion-badge v-if="authStore.isSorare && platform.slug == 'sorare' && authStore.isLoggedIn"
                        color="success" mode="ios" class="platform-link-badge">
                        <ion-icon src="/assets/icons/link.svg" v-if="authStore.isSorare"></ion-icon>
                    </ion-badge>

                    <ion-badge v-if="authStore.isUC && platform.slug == 'ultimate-champions' && authStore.isLoggedIn"
                        color="success" mode="ios" class="platform-link-badge">
                        <ion-icon src="/assets/icons/link.svg" v-if="authStore.isUC"></ion-icon>
                    </ion-badge>
                    <ion-icon class="arrow-icon" src="/assets/icons/expand_less.svg"></ion-icon>
                </div>
            </ion-row>
        </div>
        <div class="platform-tools" v-if="platform && platform.slug">
            <div class="platform-selected" @click="goBack()"
                v-track-click="{ category: 'menu', action:'back-platform-overview', label: platform.slug }">
                <ion-icon class="arrow-icon-selected" src="/assets/icons/expand_less.svg"></ion-icon>
                <ion-img :alt="platform.icon" v-if="platform && platform.icon" :src="platform.icon"></ion-img>
                <ion-text v-else>{{ 'General' }}</ion-text>
            </div>

            <ion-col cols="6" class="platform-col" v-if="selectedPlatform">
                <ion-row v-if="categorizedTools.general.length">

                    <ion-col v-for="tool in categorizedTools.general" :key="tool.name">
                        <div v-if="!tool.isConnected" class="tool-item"
                            @click="selectedPlatform == 'sorare' ? authStore.requestConnectSorare() : goTo('/profile', '', selectedPlatform)"
                            v-track-click="{ category: 'menu', action:'connect-account', label: selectedPlatform + ' ' + tool.name }">
                            <div class="tool-image">
                                <ion-icon :src="tool.icon"></ion-icon>
                            </div>
                            <span>
                                {{ tool.name }}
                            </span>
                        </div>

                    </ion-col>
                </ion-row>
                <ion-row v-if="categorizedTools.football.length" class="tool-main-container">
                    <div class="subcategory-tools">
                        <ion-icon src="/assets/icons/IoFootball.svg"></ion-icon>
                        <ion-text>Football</ion-text>
                    </div>

                    <div class="tool-container">
                        <ion-col @click="goTo(tool.url, 'football', selectedPlatform)" cols="4"
                            v-for="tool in categorizedTools.football" :key="tool.name" class="tool-item"
                            v-track-click="{ category: 'menu', action:'select-tool', label: selectedPlatform + '-' + tool.name }">
                            <div class="tool-image">
                                <ion-icon :src="tool.icon"></ion-icon>
                            </div>
                            <span>
                                {{ tool.name }}
                            </span>
                        </ion-col>
                    </div>
                </ion-row>
                <ion-row v-if="categorizedTools.basketball.length" class="tool-main-container">
                    <div class="subcategory-tools">
                        <ion-icon src="/assets/icons/IoBasketballSharp.svg"></ion-icon>

                        <ion-text>Basketball</ion-text>
                    </div>
                    <div class="tool-container">
                        <ion-col
                            @click="goTo(tool.url, selectedPlatform == 'sorare' ? 'nba' : 'basketball', selectedPlatform)"
                            cols="4" v-for="tool in categorizedTools.basketball" :key="tool.name" class="tool-item"
                            v-track-click="{ category: 'menu', action:'select-tool', label: selectedPlatform + '-' + tool.name }">
                            <div class="tool-image">
                                <ion-icon :src="tool.icon"></ion-icon>
                            </div>
                            <span>
                                {{ tool.name }}
                            </span>
                        </ion-col>
                    </div>
                </ion-row>
            </ion-col>
        </div>

    </ion-row>
</template>

<script lang="ts">
import { IonIcon, IonRow, IonImg, IonBadge, IonText } from "@ionic/vue";
import { BASE_URL } from '@/constants/api';
import { computed, defineComponent, onMounted, ref } from "vue";
import { useAuthStore } from "@/store/useAuth";
import router from "@/router";
import { PlatformsStructure, ToolData } from "@/interfaces/menuTools";
import { toolsData } from "@/constants/toolsMenu";
import { SharpPlatform } from "@/interfaces/sharpplatform";
export default defineComponent({
    components: {
        IonIcon, IonRow, IonImg, IonBadge, IonText
    },
    setup(props, { emit }) {
        const closeModal = () => {
            emit("close");
        };
        const selectedPlatform = ref('');
        const authStore = useAuthStore();
        const platforms = ref({});
        const platform = ref({} as SharpPlatform)
        const goTo = (url, category, selectedPlatform) => {
            closeModal();
            const isRootRoute = router.currentRoute.value.name === 'landing' || router.currentRoute.value.name == 'games' || router.currentRoute.value.name == 'articles';
            if (url == '/' || url == '/profile') {
                router.push({ path: url });
            }
            else {
                authStore.setSharpPlatform(selectedPlatform, category, true, isRootRoute, url)
                if (isRootRoute) {
                    if (url == '/player-availability' && selectedPlatform == 'general') {
                        router.push({ path: url, query: { platform: 'all', sport: category } })
                    }
                    else {
                        router.push({ path: url, query: { platform: selectedPlatform, sport: category } })
                    }

                }
            }
        }

        const selectPlatform = (platformSlug) => {
            selectedPlatform.value = platformSlug.slug.toLowerCase();
            platform.value = platformSlug;
        };

        const goBack = () => {
            selectedPlatform.value = '';
            platform.value = {} as SharpPlatform;
        }

        const createPlatformsStructure = (data: ToolData[]): PlatformsStructure => {
            return data.reduce((acc: PlatformsStructure, { name, platforms, category, icon }) => {
                Object.entries(platforms).forEach(([platformKey, { url }]) => {
                    const slug = platformKey.toLowerCase();
                    if (!acc[slug]) {
                        acc[slug] = { general: [], football: [], basketball: [] };
                    }
                    acc[slug][category.toLowerCase()].push({ name, url, icon });
                });
                return acc;
            }, {});
        };
        platforms.value = createPlatformsStructure(toolsData);

        onMounted(() => {
            if (authStore.isLoggedIn && authStore.isSorare) {
                platforms.value['sorare']['general'][0].isConnected = true
            }
            else {
                platforms.value['sorare']['general'][0].isConnected = false
            }

            if (authStore.isLoggedIn && authStore.isUC) {
                platforms.value['ultimate-champions']['general'][0].isConnected = true
            }
            else {
                platforms.value['ultimate-champions']['general'][0].isConnected = false
            }
        })

        const categorizedTools = computed(() => {
            const selectedData = platforms.value[selectedPlatform.value];
            return selectedData || { general: [], football: [], basketball: [] };
        });

        return {
            BASE_URL,
            authStore,
            goTo,
            selectedPlatform,
            selectPlatform,
            goBack,
            categorizedTools,
            platform
        }
    }
})
</script>

<style lang="sass" scoped>
.tools-items
    background: #222935
    border-top-left-radius: 16px
    border-top-right-radius: 16px
    .single-profile-item
        margin-top: 12px
        padding: 24px
        background-color: rgba(22, 28, 35, 0.60)
        border-radius: 16px
        display: flex
        align-items: center
        ion-text
            font-size: 16px
            margin-left: 10px
        .arrow-icon
            margin-left: auto
            transform: rotate(90deg)
        ion-icon
            &:first-child
                width: 24px
                height: 24px
.tools-platforms
    display: flex
    flex-direction: column
    width: 100%
    padding: 24px
    .single-platform-tools
        margin: 0
        padding: 12px
        display: flex
        align-items: center
        justify-content: space-between
        height: 62px
        div
            display: flex
            align-items: center
        &:not(:last-of-type)
            border-bottom: 1px solid #00AB55
           

        &:first-of-type
            ion-text
                font-size: 20px
                color: white
        ion-img
            height: 32px
            max-width: 140px
            object-fit: contain
        ion-icon
            transform: rotate(90deg)
.platform-link-badge
  font-size: 18px
  line-height: 1
  width: 24px
  height: 24px
  padding: 0
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%
  margin-right: 8px
  transform: rotate(90deg)
.platform-tools
    width: 100%
    .platform-selected
        border-top-left-radius: 16px
        border-top-right-radius: 16px
        padding: 24px
        background: #00AB55
        width: 100%
        display: flex
        align-items: center
        .arrow-icon-selected
            margin-right: 12px
            transform: rotate(-90deg)
        ion-img
            height: 32px
            max-width: 180px
            object-fit: contain
.platform-col
  padding: 24px
  display: flex
  flex-direction: column
.tool-main-container 
  display: flex
  flex-direction: column
  flex-wrap: wrap
  span
    min-height: 25px
    max-height: 25px
    display: flex
    align-items: center
.tool-container
    display: flex
    flex-wrap: wrap
.tool-item 
  display: flex
  min-width: 95px
  max-width: 95px
  min-height: 88px
  max-height: 88px
  background-color: #1A212A
  border-radius: 8px
  padding: 8px
  margin-right: 8px
  margin-bottom: 16px
  font-size: 12px
  cursor: pointer
  display: flex
  flex-direction: column
  justify-content: space-between
  color: #fff
  &:hover
    background-color: #1a212a47
.tool-image
    width: 40px
    height: 40px
    border-radius: 8px
    background: linear-gradient(225deg, #662D91 0%, #10DFEF 100%)
    display: flex
    justify-content: center
    align-items: center
    ion-icon
        width: 24px
        height: 24px

.subcategory-tools
    display: flex
    align-items: center
    margin-bottom: 8px
    ion-text
        font-size: 16px
    ion-icon
        width: 24px
        height: 24px
        margin-right: 8px
</style>