type ImageType = ('team' | 'competition' | 'player' | 'expert' | 'user');

export const setDefaultImage = (type: ImageType) => {
  switch (type) {
    case 'team':
      return '/assets/images/placeholders/team.svg';
    case 'competition':
      return '/assets/images/placeholders/league.svg';
    case 'player':
      return '/assets/images/placeholders/player.svg';
    case 'expert':
      return '/assets/images/placeholders/expert.svg';
    case 'user':
      return '/assets/images/placeholders/user.svg';        
    default:
      return '/assets/images/placeholders/team.svg';
  }
}

export const getFileSize = (base64String: string) => {
  const bytes = new Uint8Array(base64String.length);

  for (let i = 0; i < base64String.length; i++) {
    bytes[i] = base64String.charCodeAt(i);
  }

  const blob = new Blob([bytes.buffer], { type: 'image/jpeg' });

  // Check file size
  const fileSizeInBytes = blob.size;
  const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024);
  return fileSizeInMegabytes;
}