import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b0ffa9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "tool-image" }
const _hoisted_4 = { class: "subcategory-tools" }
const _hoisted_5 = { class: "tool-container" }
const _hoisted_6 = { class: "tool-image" }
const _hoisted_7 = { class: "subcategory-tools" }
const _hoisted_8 = { class: "tool-container" }
const _hoisted_9 = { class: "tool-image" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_track_click = _resolveDirective("track-click")!

  return (_openBlock(), _createBlock(_component_ion_row, { class: "desktop-tools-wrapper" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_col, {
        class: "tools-platforms",
        cols: "6"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authStore.sharpPlatforms, (platform) => {
            return (_openBlock(), _createBlock(_component_ion_row, {
              key: platform.slug.replace('_', '-'),
              class: _normalizeClass([{ 'active': platform.slug.replace('_', '-') == _ctx.selectedPlatform }, "single-platform-tools"]),
              onMouseover: ($event: any) => (_ctx.selectPlatform(platform.slug.replace('_', '-')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_img, {
                  alt: platform.icon,
                  src: platform.icon
                }, null, 8, ["alt", "src"]),
                _createElementVNode("div", _hoisted_1, [
                  (_ctx.authStore.isSorare && platform.slug == 'sorare' && _ctx.authStore.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 0,
                        color: "success",
                        mode: "ios",
                        class: "platform-link-badge"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.authStore.isSorare)
                            ? (_openBlock(), _createBlock(_component_ion_icon, {
                                key: 0,
                                src: "/assets/icons/link.svg"
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.authStore.isUC && platform.slug.replace('_', '-') == 'ultimate-champions' && _ctx.authStore.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 1,
                        color: "success",
                        mode: "ios",
                        class: "platform-link-badge"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.authStore.isUC)
                            ? (_openBlock(), _createBlock(_component_ion_icon, {
                                key: 0,
                                src: "/assets/icons/link.svg"
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_icon, {
                    class: "arrow-icon",
                    src: "/assets/icons/expand_less.svg"
                  })
                ])
              ]),
              _: 2
            }, 1032, ["class", "onMouseover"]))
          }), 128))
        ]),
        _: 1
      }),
      (_ctx.selectedPlatform)
        ? (_openBlock(), _createBlock(_component_ion_col, {
            key: 0,
            cols: "6",
            class: "platform-col"
          }, {
            default: _withCtx(() => [
              (_ctx.categorizedTools.general.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categorizedTools.general, (tool) => {
                      return (_openBlock(), _createBlock(_component_ion_col, {
                        key: tool.name,
                        style: {"padding":"0"}
                      }, {
                        default: _withCtx(() => [
                          (!tool.isConnected)
                            ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "tool-item",
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.connectAccount(_ctx.selectedPlatform)))
                              }, [
                                _createElementVNode("div", _hoisted_3, [
                                  _createVNode(_component_ion_icon, {
                                    src: tool.icon
                                  }, null, 8, ["src"])
                                ]),
                                _createElementVNode("span", null, _toDisplayString(tool.name), 1)
                              ])), [
                                [_directive_track_click, { category: 'menu', action:'connect-account', label: _ctx.selectedPlatform + ' ' + tool.name }]
                              ])
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.categorizedTools.football.length)
                ? (_openBlock(), _createBlock(_component_ion_row, {
                    key: 1,
                    class: "tool-main-container"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_ion_icon, { src: "/assets/icons/IoFootball.svg" }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Football")
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categorizedTools.football, (tool) => {
                          return _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                            to: `${tool.url}?platform=${_ctx.selectedPlatform}&sport=football`,
                            cols: "4",
                            key: tool.name,
                            class: "tool-item"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_6, [
                                _createVNode(_component_ion_icon, {
                                  src: tool.icon
                                }, null, 8, ["src"])
                              ]),
                              _createElementVNode("span", null, _toDisplayString(tool.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"])), [
                            [_directive_track_click, { category: 'menu', action:'select-tool', label: _ctx.selectedPlatform + '-' + tool.name }]
                          ])
                        }), 128))
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.categorizedTools.basketball.length)
                ? (_openBlock(), _createBlock(_component_ion_row, {
                    key: 2,
                    class: "tool-main-container"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_ion_icon, { src: "/assets/icons/IoBasketballSharp.svg" }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Basketball")
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categorizedTools.basketball, (tool) => {
                          return _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                            to: `${tool.url}?platform=${_ctx.selectedPlatform}&sport=basketball`,
                            cols: "4",
                            key: tool.name,
                            class: "tool-item"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_9, [
                                _createVNode(_component_ion_icon, {
                                  src: tool.icon
                                }, null, 8, ["src"])
                              ]),
                              _createElementVNode("span", null, _toDisplayString(tool.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"])), [
                            [_directive_track_click, { category: 'menu', action:'select-tool', label: _ctx.selectedPlatform + '-' + tool.name }]
                          ])
                        }), 128))
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}