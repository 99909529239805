import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77e2f3d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt", "src"]
const _hoisted_2 = {
  key: 1,
  src: "/assets/images/placeholders/user.svg",
  alt: "user.svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(_component_ion_row, { class: "profile-items" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_col, {
        size: "12",
        class: "logout",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode("Logout")
            ]),
            _: 1
          }),
          _createVNode(_component_ion_icon, { src: "/assets/icons//headerIcons/IoLogOutSharp.svg" })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_col, {
        size: "12",
        class: "single-profile-item",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goTo('/profile')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_avatar, { class: "header-avatar" }, {
            default: _withCtx(() => [
              (_ctx.$auth?.user?.avatar)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    alt: _ctx.$auth.user.avatar,
                    src: `${_ctx.BASE_URL}/assets/${_ctx.$auth.user.avatar}?fit=cover&width=100&height=100&quality=80`
                  }, null, 8, _hoisted_1))
                : (_openBlock(), _createElementBlock("img", _hoisted_2))
            ]),
            _: 1
          }),
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode("My Profile")
            ]),
            _: 1
          }),
          _createVNode(_component_ion_icon, {
            class: "arrow-icon",
            src: "/assets/icons/expand_less.svg"
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_col, {
        size: "12",
        class: "single-profile-item",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goTo('/profile/favorites')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, { src: "/assets/icons//headerIcons/IoHeartSharp.svg" }),
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode("Favorite teams")
            ]),
            _: 1
          }),
          _createVNode(_component_ion_icon, {
            class: "arrow-icon",
            src: "/assets/icons/expand_less.svg"
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}