import { SUPPORTED_PLATFORMS, SUPPORTED_SPORTS } from "@/constants/platforms";
import i18n from "@/i18n";
import { createRouter } from "@ionic/vue-router";
import { createWebHistory, RouteRecordRaw } from "vue-router";
import { useAuthStore } from "../store/useAuth";
import { APP_URL } from "@/constants/api";

let startTime = Date.now();

const { t } = i18n.global as any;

const redirectToHomeOnLoggedIn = (to: any, from: any, next: any) => {
  if (useAuthStore().loggedIn) next({ name: "landing" });
  else next();
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "landing",
    meta: {
      title: "Landing Page",
      group: 'games',
      layout: "public",
    },
    component: () => import('../views/landing/LandingPage.vue'),
    beforeEnter: (to, from, next) => {
      localStorage.removeItem('sharpplatform');
      if (to.query.platform || to.query.sport) {
        const toQ = { ...to.query };
        delete toQ.platform;
        delete toQ.sport;
        next({
          ...to,
          query: toQ
        });
      }

      else next()
    },
  },
  {
    path: "/home",
    name: "platinum",
    meta: {
      title: "Home Page",
      group: 'home',
      layout: "public",
    },
    component: () => import('../views/landing/PlatinumPage.vue'),
    beforeEnter: (from, to, next) => {
      if (window.innerWidth > 991) {
        next({ name: 'landing' });
      }
      next();
    }
  },
  {
    path: '/games/:id',
    name: "games",
    meta: {
      layout: "public",
      group: 'games',
    },
    component: () => import('../views/landing/LandingPage.vue'),
    beforeEnter: (to, from, next) => {
      if (from.name === 'platinum' && window.innerWidth < 992) {
        to.query.prev = 'home';
      }
      if (to.name === 'games') {
        localStorage.removeItem('sharpplatform');
      }
      next();
    },
  },
  {
    path: "/guide",
    name: "guide",
    redirect: '/',
  },

  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/LoginPage.vue"),
    meta: {
      title: "Login",
      layout: "auth",
      group: 'auth',
    },
    beforeEnter: redirectToHomeOnLoggedIn,
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/auth/RegisterPage.vue"),
    meta: {
      title: "Register",
      layout: "auth",
      group: 'auth',
    },
    beforeEnter: redirectToHomeOnLoggedIn,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("../views/auth/ForgotPasswordPage.vue"),
    meta: {
      title: "Forgot Password",
      layout: "auth",
      group: 'auth',
    },
    beforeEnter: redirectToHomeOnLoggedIn,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/auth/ResetPasswordPage.vue"),
    meta: {
      title: "Reset Password",
      layout: "auth",
      group: 'auth',
    },
    beforeEnter: redirectToHomeOnLoggedIn,
  },
  {
    path: "/lineups",
    name: "lineups",
    redirect: '/',    
  },

  {
    path: "/projected-points",
    name: "projectedpoints",
    component: () => import("../views/projected-points/ProjectedPointsSorare.vue"),
    meta: {
      group: "tools",
      title: t("pages.expectedscores.projectedPointsTitle"),
      platforms: ['sorare', 'ultimate-champions'],
      sports: ['basketball'],
    }
  },

  {
    path: "/lineups/game/:id",
    name: "game",
    redirect(to) {
      return { name: "games", id: to.params.id };
    },
  },
  {
    path: "/optimizer",
    name: "optimizer",
    component: () => import("../views/optimizer/NbaOptimizerPage.vue"),
    meta: {
      group: "tools",
      title: 'Lineup Optimizer',
      platforms: ['sorare'],
      sports: ['basketball'],
    },
  },
  {
    path: "/player-profiles",
    redirect: 'player-availability',
  },
  {
    path: "/player-availability",
    name: "player-availability",
    component: () => import("../views/players/PlayerAvailabilityPage.vue"),
    meta: {
      group: "tools",
      sports: ['football'],
      title: t("pages.playerAvailability.title"),
    },
  },
  {
    path: "/calendar",
    name: "calendar",
    component: () => import("../views/calendar/CalendarPage.vue"),
    meta: {
      group: "tools",
      title: t("pages.calendar.title"),
      platforms: ['sorare', 'ultimate-champions'],
    },
  },

  {
    path: "/nba-subscription",
    name: "nba-subscription",
    component: () => import("../views/nba-subscription/NbaSubscription.vue"),
    meta: {
      group: "more",
      title: t("pages.nbaSubscription.title"),
    },
  },

  {
    path: "/subscription",
    name: "subscription",
    component: () => import("../views/supporters-pass/SupportersPass.vue"),
    meta: {
      group: "more",
      title: t("pages.supoortersSub.title")
    },
  },

  {
    path: "/articles",
    name: "articles",
    component: () => import("../views/articles/ArticlesPage.vue"),
    meta: {
      group: "articles",
      title: t("pages.articles.title"),
      platforms: ['sorare']
    },
  },
  {
    path: "/articles/:id",
    name: "singlearticle",
    component: () => import("../views/articles/SingleArticle.vue"),
    meta: {
      group: "articles",
      title: t("pages.articles.title"),
      platforms: ['sorare'],
    },
  },

  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/profile/ProfilePage.vue"),
    meta: {
      group: "auth",
      title: t("pages.profile.title"),
      requireAuth: true,
      platforms: ['sorare', 'ultimate-champions', 'fpl'],
    },
  },

  {
    path: "/profile/favorites",
    name: "favorites",
    component: () => import("../views/profile/FavoritesPage.vue"),
    meta: {
      group: "auth",
      title: t("pages.favorites.title"),
      requireAuth: true,
      platforms: ['sorare', 'ultimate-champions', 'fpl'],
    },
  },

  {
    path: "/player/:slug",
    name: "player",
    component: () => import("../views/players/PlayerProfilePage.vue"),
    meta: {
      group: "tools",
      sports: ['football'],
      title: t("pages.player.title")
      // TODO
    },
  },
  {
    path: "/uc-projected-points",
    name: "uc-projected-points",
    component: () => import("../views/projected-points/UcProjectedPointsPage.vue"),
    meta: {
      group: "tools",
      sports: ['basketball'],
      title: t("pages.projectedpoints.title"),
      platforms: ['sorare', 'ultimate-champions'],
    }
  },
  {
    path: '/expected-scores',
    name: 'expectedscores',
    component: () => {
      // const platform = new URLSearchParams(window.location.search).get('platform');
      // if (platform == 'fpl') {
      //   return import('../views/expected-scores/fpl/ExpectedScoresPage.vue');
      // } else {
      return import('../views/expected-scores/ExpectedScoresPage.vue');
      // }
    },
    props: true,
    meta: {
      group: 'tools',
      sports: ['football'],
      title: t('pages.expectedscores.title'),
      platforms: ['sorare', 'ultimate-champions'],
    },
  },

  {
    path: '/fpl-home',
    name: 'fpl-home',
    redirect: '/',
  },
  // terms-of-use
  // imprint
  // privacy-policy
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import("../views/articles/SingleArticle.vue"),
    meta: {
      group: 'more',
      title: 'Terms of Use',
      platforms: ['sorare'],
    }
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: () => import("../views/articles/SingleArticle.vue"),
    meta: {
      group: 'more',
      title: 'Imprint',
      platforms: ['sorare'],
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import("../views/articles/SingleArticle.vue"),
    meta: {
      group: 'more',
      title: 'Privacy Policy',
      platforms: ['sorare'],
    }
  },
  {
    path: "/callbacks/sorare",
    name: 'auth-sorare',
    component: () => import("../views/profile/AuthSorarePage.vue"),
    meta: {
      group: "auth",
      title: t("pages.player.title"),
      requireAuth: true,
      platforms: ['sorare', 'ultimate-champions', 'fpl']
    },
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: () => import("../views/PageNotFound.vue"),
  },

  {
    path: "/:catchAll(.*)",
    redirect: '/404'
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (from.name === to.name && (from.query.platform !== to.query.platform || from.query.sport !== to.query.sport)){
    window.location.href = to.fullPath;
  }
  
  if (to.name == 'login' && (from.name == 'landing' || from.name == 'games' || from.name == 'profile' || from.name !== 'favorites')) {
    next();
  }
  else {
    if (
      (typeof (to.query.platform) === 'string' && to.query.platform !== 'all' && !SUPPORTED_PLATFORMS.includes(to.query.platform)) ||
      (typeof (to.query.sport) === 'string' && !SUPPORTED_SPORTS.includes(to.query.sport))
    ) {
      next({ name: "PageNotFound", query: {} });
    }

    else if (to.meta.requireAuth && !useAuthStore().isLoggedIn) {
      const loginpath = window.location.pathname;
      next({
        name: "login",
        query: { loginpath },
      });
    }
    else if ((!to.query.platform || !to.query.sport) && (to.name !== 'singlearticle' && to.name !== 'landing' && to.name !== 'games' && to.name !== 'favorites' && to.name !== 'profile' && to.name !== 'subscription' && to.name !== 'nba-subscription' && to.name !== 'articles' && to.name !== 'player-availability')) {
      const query = {
        ...to.query,
        ...(!to.query.platform && to.name !== 'landing') && { platform: from.query.platform ?? useAuthStore().sharpPlatform },
        ...(!to.query.sport && to.name !== 'landing') && { sport: from.query.sport ?? useAuthStore().sharpSport },
      }


      next({
        ...to,
        query: query
      })

    }
    else if (to.meta.sports && (to.meta.sports as string[]).length === 1 && to.query.sport !== to.meta.sports[0]){
      next({
        ...to,
        query: {
          ...to.query,
          sport: to.meta.sports[0]
        }
      });
    }
    else {
      next()
    }

  }

});

router.afterEach((to, from) => {
  const sharpsport = localStorage.getItem('sharpsport');
  if (!sharpsport || useAuthStore().sharpPlatform !== to.query.platform || useAuthStore().sharpSport !== to.query.sport) {
    if (to.query.platform && to.query.sport)
      useAuthStore().setSharpPlatform(to.query.platform as string, to.query.sport as string, false);
  }

  const existingCanonical = document.querySelector('link[rel="canonical"]');
  if (existingCanonical) {
    existingCanonical.remove();
  }

  const canonical = document.createElement('link');
  canonical.rel = 'canonical';
  canonical.href = `${APP_URL}${to.fullPath}`;
  document.head.appendChild(canonical);

  const queryParams = {};
  Object.keys(from.query).forEach(key => {
    queryParams[`${key}`] = from.query[key];
  });

  // Calculate time spent on the previous page
  const timeSpent = from.name ? Date.now() - startTime : undefined;

  // window.gtag('config', 'G-H1C7LC9Y4F', {
  //   page_path: to.fullPath
  // });

  // Send a time_spent event to GA4 with all query parameters and time spent
  window.gtag('event', 'time_spent', {
    'page_path': from.fullPath,
    // 'page_referrer': from.name || '',
    'page_title': from.name,
    'page_location': window.location.href,
    'next_page_name': to.name,
    'next_page_path': to.fullPath,
    ...queryParams, // Include all query parameters
    'label': from.name,
    'value': timeSpent,
    'category': 'time_spent'
  });
  // Update the start time for the next page
  startTime = Date.now();
});

export default router; 