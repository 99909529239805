
export const toolsData = [
    {
        name: 'Connect your account',
        platforms: {
            'sorare': { url: '/connect-account?sorare' },
            'ultimate-champions': { url: '/profile'},
        },
        category: 'General',
        icon: '/assets/icons/tools/connect.svg',
        
    },
    {
        name: 'Expected Scores',
        platforms: {
            'sorare': { url: '/expected-scores' },
            'ultimate-champions': { url: '/expected-scores' },
        },
        category: 'Football',
        icon: '/assets/icons/tools/expected-scores.svg'
    },
    // {
    //     name: 'Player Availability',
    //     platforms: {
    //         'sorare': { url: '/player-availability' },
    //         'ultimate-champions': { url: '/player-availability' },
    //         'FPL': { url: '/player-availability' },
    //         'general': { url: '/player-availability' },
    //     },
    //     category: 'Football',
    //     icon: '/assets/icons/tools/player-availability.svg'
    // },
    // {
    //     name: 'Team Builder',
    //     platforms: {
    //         'sorare': { url: '/builder' },
    //     },
    //     category: 'Football',
    //     icon: '/assets/icons/tools/team-builder.svg'
    // },
    // {
    //     name: 'Calendar',
    //     platforms: {
    //         'sorare': { url: '/calendar' },
    //         'ultimate-champions': { url: '/calendar' },
    //     },
    //     category: 'Football',
    //     icon: '/assets/icons/tools/calendar.svg'

    // },
    {
        name: 'NBA Pass',
        platforms: {
            'sorare': { url: '/nba-subscription' },
        },
        category: 'Basketball',
        icon: '/assets/images/sharpicon.svg'

    },
    {
        name: 'Projected Points',
        platforms: {
            'sorare': { url: '/projected-points' },
            'ultimate-champions': { url: '/uc-projected-points' },
        },
        category: 'Basketball',
        icon: '/assets/icons/tools/expected-scores.svg'

    },
    {
        name: 'Lineup Optimizer',
        platforms: {
            'sorare': { url: '/optimizer' },
        },
        category: 'Basketball',
        icon: '/assets/icons/tools/team-builder.svg'

    },
];