export const localeDay = (dateStr: string) => {
    const timezoned = dateStr.length === 24 ? dateStr : dateStr.length === 10 ? dateStr+'T00:00:00.000Z' : dateStr+'.000Z';
    const date = new Date(timezoned);
    const formatted = date.toLocaleDateString('en-GB', { weekday: "short" });
    return formatted;
}

/**
 * Converts 
 * @param dateStr 
 * @returns 
 */
export const localeDateMonth = (dateStr: string) => {
    const timezoned = dateStr.length === 24 ? dateStr : dateStr.length === 10 ? dateStr+'T00:00:00.000Z' : dateStr+'.000Z';
    const date = new Date(timezoned);
    const formatted = date.toLocaleDateString('en-GB', { month: "2-digit", day: "2-digit" }).split('/').join('.');
    return formatted;
}

export const localeGameDate = (dateStr: string) => {
    const timezoned = dateStr.length === 24 ? dateStr : dateStr.length === 10 ? dateStr+'T00:00:00.000Z' : dateStr+'.000Z';
    const date = new Date(timezoned);
    const formatted = date.toLocaleDateString('en-GB', { 
        weekday: "short", 
        day: '2-digit', 
        month: 'short',
        hour: '2-digit',
        minute: '2-digit'
    });
    return formatted;
}

export const standardDate = (dateStr: string) => {
    const timezoned = dateStr.length === 24 ? dateStr : dateStr.length === 10 ? dateStr+'T00:00:00.000Z' : dateStr+'.000Z';
    const date = new Date(timezoned);
    const formatted = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',

    });
    
    return formatted.split('/').join('.');
}

export const backendDate = (dateStr: string) => {
    const timeZoneOffset = new Date().getTimezoneOffset(); 
    const timezoned = new Date(dateStr);
    
    timezoned.setMinutes(timezoned.getMinutes() + timeZoneOffset);
    
    const year = timezoned.getFullYear();
    const month = String(timezoned.getMonth() + 1).padStart(2, '0');
    const day = String(timezoned.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
}

export const localePostDate = (dateStr: string) => {
    const timezoned = dateStr.length === 24 ? dateStr : dateStr.length === 10 ? dateStr+'T00:00:00.000Z' : dateStr+'.000Z';
    const date = new Date(timezoned);
    const formatted = date.toLocaleDateString('en-GB', { 
        day: '2-digit', 
        month: 'short',
        year: 'numeric'
    });
    return formatted;
}

export const localeDayAndTime = (dateStr: string) => {
    const timezoned = dateStr.length === 24 ? dateStr : dateStr.length === 10 ? dateStr+'T00:00:00.000Z' : dateStr+'.000Z';
    const date = new Date(timezoned);
    const formatted = date.toLocaleDateString('en-GB', { 
        weekday: "short", 
        hour: '2-digit',
        minute: '2-digit'
    });
    return formatted;
}

export const localeDayMonthTime = (dateStr: string) => {
    const timezoned = dateStr.length === 24 ? dateStr : dateStr.length === 10 ? dateStr+'T00:00:00.000Z' : dateStr+'.000Z';
    const date = new Date(timezoned);
    const formatted = date.toLocaleDateString('en-GB', { 
        day: '2-digit', 
        month: 'short',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    });
    return formatted;
}

export const localeTime = (dateStr: string) => {
    const timezoned = dateStr.length === 24 ? dateStr : dateStr.length === 10 ? dateStr+'T00:00:00.000Z' : dateStr+'.000Z';
    const date = new Date(timezoned);
    const formatted = date.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit'
    });
    return formatted;
}

export const getAge = (birthdate: string) => {
    const bd = new Date(birthdate + 'T00:00:00.641Z');
    const bdTime = bd.getTime();
    const todayTime = new Date().getTime();
    const diff = todayTime - bdTime;
    // TODO make the count most precise
    return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
}

export function getUserTimezoneOffset() {
    const now = new Date();
    const timezoneOffsetMinutes = now.getTimezoneOffset();
    
    const sign = timezoneOffsetMinutes <= 0 ? '+' : '-';
    const offsetHours = Math.abs(Math.floor(timezoneOffsetMinutes / 60)).toString().padStart(2, '0');
    const offsetMinutes = (Math.abs(timezoneOffsetMinutes) % 60).toString().padStart(2, '0');
  
    return `GMT${sign}${offsetHours}:${offsetMinutes}`;
}

export const getGameDate = (originalDateString: string) => {
    const originalDate = new Date(originalDateString);
    const userTimeZoneDate = new Date(originalDate.getTime() - originalDate.getTimezoneOffset() * 60000 + new Date().getTimezoneOffset() * 60000);


    const formatted = userTimeZoneDate.toLocaleString('en-GB', {
        weekday: "short",
        day: '2-digit',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit'
    });
    return formatted;
}