<template>
<div></div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import router from "@/router";
import { setSchema } from "@/services/schema";

export default defineComponent({
  name: "SchemaOrg",
  components: {},

  setup() {

    const specificRoutes = ['games', 'singlearticle'];
    const routeName = computed(() => {
        const route = router.currentRoute.value;
        return route.name;
    });

    onMounted(() => {
      requestSetSchema();
    });
    
    const requestSetSchema = () => {
      if (!specificRoutes.includes(routeName.value.toString())) {
        setSchema('general');
      }
    }

    router.afterEach(() => {
      if (!specificRoutes.includes(routeName.value.toString())) {
        requestSetSchema();
      }
    })

  }

});
</script>