<template>
    <ion-row class="community-items">

        <ion-col size="12" class="single-profile-item">
            <ion-icon src="/assets/icons/subscription.svg"></ion-icon>
            <ion-text>Subscriptions</ion-text>

            <ion-row class="community-socials">
            <ion-col size="12">
                <div class="tool-container">
                    <ion-col size="6"
                         class="tool-item"
                         @click="goTo('/nba-subscription')">
                        <div class="tool-image">
                            <ion-icon src="/assets/images/sharpicon.svg"></ion-icon>
                        </div>
                        <span>
                            NBA Pass
                        </span>
                    </ion-col>
                    <ion-col size="6"
                         class="tool-item"
                         @click="goTo('/subscription')">
                        <div class="tool-image">
                            <ion-icon src="/assets/images/sharpicon.svg"></ion-icon>
                        </div>
                        <span>
                            Supporter's Pass
                        </span>
                    </ion-col>
                </div>
            </ion-col>
            </ion-row>
        </ion-col>

        <ion-col size="12" class="single-profile-item" @click="goTo('articles')">
            <ion-icon src="/assets/icons/mobileTabsIcons/IoReaderOutline.svg"></ion-icon>

            <ion-text>Articles</ion-text>
            <ion-icon class="arrow-icon" src="/assets/icons/expand_less.svg"></ion-icon>
        </ion-col>

        <ion-col size="12" class="single-profile-item">
            <ion-icon src="/assets/icons/notifications_filled.svg"></ion-icon>
            <ion-text>Socials</ion-text>

            <ion-row class="community-socials">
                <a href="https://twitter.com/Play_Sharper" class="single-social" v-track-click="{ category: 'menu', action: 'open-socials', label: 'X'}">
                    <ion-icon src="/assets/icons/brand/twitter.svg"></ion-icon>
                </a>

                <a href="https://t.me/playsharper" class="single-social" v-track-click="{ category: 'menu', action: 'open-socials', label: 'Telegram'}">
                    <ion-icon src="/assets/icons/brand/telegram.svg"></ion-icon>
                </a>

                <a href="https://www.youtube.com/@PlaySharper" class="single-social" v-track-click="{ category: 'menu', action: 'open-socials', label: 'YouTube'}">
                    <ion-icon src="/assets/icons/brand/youtube.svg"></ion-icon>
                </a>
            </ion-row>
        </ion-col>

        <ion-col size="12" class="single-profile-item newsletter">
            <ion-text>
                <ion-icon src="/assets/icons/IoMail.svg"></ion-icon>
                Newsletter</ion-text>

            <div class="community-newsletter-wrapper">
                <ion-input v-model="email" type="email" autocomplete="email" enterkeyhint="done" inputmode="email"
                        placeholder="Enter your e-mail address" v-track-click="{ category: 'menu', action: 'tap-newsletter-input'}" />
                    <ion-button mode="ios" color="success" @click="subNewsletter(email)" v-track-click="{ category: 'menu', action:'subscribe-newsletter'}">
                        Subscribe
                    </ion-button>
            </div>
            <ion-toast :message="messageConfirm" position="top" color="success" :isOpen="showToast"
                    css-class="toast-newsletter-top-right"></ion-toast>
                <ion-toast :message="messageConfirm" position="top" color="danger" :isOpen="showToastError"
                    css-class="toast-newsletter-top-right"></ion-toast>
        </ion-col>

        <ion-col size="12" class="single-profile-item" @click="goTo('download')"
            v-track-click="{ category: 'menu', action: 'download-app', label: 'mobile-single-button'}">
            <ion-icon src="/assets/icons/playsharper_background.svg"></ion-icon>

            <ion-text>Download App</ion-text>
            <ion-icon class="arrow-icon" src="/assets/icons/expand_less.svg"></ion-icon>
        </ion-col>

        <ion-col size="12" class="ion-text-center">
            <page-footer :forceShow="true" />
        </ion-col>

    </ion-row>
</template>

<script lang="ts">
import { IonText, IonIcon, IonCol, IonRow, IonButton, IonInput, isPlatform,IonToast } from "@ionic/vue";
import { BASE_URL } from '@/constants/api';
import { defineComponent, ref } from "vue";
import { useAuthStore } from "@/store/useAuth";
import router from "@/router";
import PageFooter from "@/components/shared/PageFooter.vue";


export default defineComponent({
    components: {
        IonText, 
        IonIcon, 
        IonCol, 
        IonRow, 
        IonButton, 
        IonInput,
        IonToast,
        PageFooter,
    },
    setup(props, { emit }) {
        const oniOS = isPlatform('ios');
        const onAndroid = isPlatform('android');

        const closeModal = () => {
            emit("close");
        };
        const authStore = useAuthStore();

        const goTo = (url: string) => {
            if (url == 'download') {
                if (oniOS) {
                    window.open('https://apps.apple.com/sa/app/playsharper/id1633083532', '_blank').focus();
                }
                if (onAndroid) {
                    window.open('https://play.google.com/store/apps/details?id=com.playsharper.app', '_blank').focus();
                }
            }
            else{
                router.push({ path: url });
            }
            closeModal();
        }

        const openArticle = (id: number) => {
            router.replace({ path: `/articles/${id}` });
            closeModal();
        }

        const email = ref('');
        const showToast = ref(false);
        const showToastError = ref(false);
        const messageConfirm = ref('');
        const subNewsletter = async (email: string) => {
            try {
                await authStore.subNewsletter(email);
                messageConfirm.value = 'Successfully subscribed to the Playsharper newsletter.';
                showToast.value = true;
                setTimeout(() => {
                    showToast.value = false;
                }, 1523000);

            } catch (err) {
                const errorMessage = err.response && err.response.data && err.response.data.error
                    ? err.response.data.error
                    : 'Unknown error occurred. Please try again.';
                messageConfirm.value = errorMessage;
                showToastError.value = true;
                setTimeout(() => {
                    showToastError.value = false;
                }, 1523000);

            }
        };

        return {
            BASE_URL,
            authStore,
            goTo,
            email,
            subNewsletter,
            messageConfirm,
            showToast,
            showToastError,
            openArticle,
        }
    }
})
</script>

<style lang="sass" scoped>
.community-items
    padding: 24px
    background: #222935
    border-top-left-radius: 16px
    border-top-right-radius: 16px
    .single-profile-item
        margin-top: 12px
        padding: 0px 24px
        height: 72px
        background-color: rgba(22, 28, 35, 0.60)
        border-radius: 16px
        display: flex
        align-items: center
        &:last-of-type
            ion-icon
                width: 24px
                height: 24px
        ion-text
            font-size: 16px
            margin-left: 10px
            color: #fff
        .arrow-icon
            margin-left: auto
            transform: rotate(90deg)
        &.newsletter
            display: flex
            height: fit-content
            flex-direction: column
            padding: 24px
            align-items: flex-start
            ion-text
                display: flex
                align-items: center
                ion-icon
                    margin-right: 8px
.single-social
    color: white
    &:not(:first-of-type)
        margin-left: 8px
    &:nth-child(2)
        margin-right: 6px
.community-newsletter-wrapper
    background-color: #161C23
    border-radius: 14px
    padding-left: 16px
    height: 56px
    display: flex
    align-items: center
    margin-top: 24px
    width: 100%
    font-size: 14px
    @media only screen and (min-width: 600px) and (max-width: 1024px)
        width: fit-content
    ion-input
        height: 56px
        max-width: 250px
    ion-button
        height: 60px

.community-socials
    margin: 0
    margin-left: auto
    display: flex
    align-items: center
    a
        &:first-of-type
            ion-icon
                width: 34px
                height: 34px
        &:nth-child(2)
            ion-icon    
                width: 28px
                height: 28px
        &:last-of-type
            ion-icon
                width: 32px
                height: 32px



.tool-container
    display: flex
    flex-wrap: wrap
    margin-left: 8px

.tool-item 
  display: flex
  width: 85px
  height: 70px
  background-color: #1A212A
  border-radius: 8px
  padding: 4px
  font-size: 12px
  cursor: pointer
  display: flex
  flex-direction: column
  justify-content: space-around
  span
    font-size: 0.85em

.tool-image
    width: 34px
    height: 34px
    border-radius: 8px
    background: linear-gradient(225deg, #662D91 0%, #10DFEF 100%)
    display: flex
    justify-content: center
    align-items: center
    ion-icon
        width: 28px
        height: 28px

</style>