import { BASE_URL, FASTIFY_BASE_URL, RESET_URL, STATIC_TOKEN } from "@/constants/api";
import { defineStore } from "pinia";
import axios from "axios";
import { Directus } from '@directus/sdk';
import { Device } from '@capacitor/device';

import { Credentials, SorareCard, User, CurrencyRate } from "@/interfaces/user";
import { Team } from "@/interfaces/lineup";

import { useErrorStore } from "./useError";
import router from "@/router";
// import { getUserToken } from "@/services/firebase";
import { NotificationItem } from "@/interfaces/notification";
import { PushNotifications, Token } from '@capacitor/push-notifications';

import {  DEFAULT_PLATFORM, DEFAULT_SPORT, SUPPORTED_SPORTS, sharpPlatforms } from "@/constants/platforms";

import { CURRENCIES } from "@/constants/currency";
import { isPlatform } from "@ionic/vue";
import { getFileSize } from "@/services/images";
// const isMobileApp = () => (isPlatform("android") || isPlatform("ios")) && !isPlatform("mobileweb")
const sdk = new Directus(BASE_URL, { auth: { mode: 'json', msRefreshBeforeExpires: 60000 } });

export const useAuthStore = defineStore("auth", {
  state: () => ({
    loggedIn: !localStorage.getItem('auth_token') || localStorage.getItem('auth_token') === STATIC_TOKEN ? false : true,
    user: {} as User,
    editableUser: {} as User,
    quotas: {
      lineups: {
        maximum: -1,
        remaining: -1
      }
    },
    favoriteTeams: [] as Team[],
    notifications: [] as NotificationItem[],
    cards: [] as SorareCard[],
    refreshingCards: false,
    sharpPlatforms: sharpPlatforms,
    sharpSports: SUPPORTED_SPORTS,
    updatingUser: false,
    userUpdated: false,
    rates: {} as CurrencyRate,
    games: sharpPlatforms,
    // sharpPlatform: isMobileApp ? DEFAULT_PLATFORM : localStorage.getItem('sharpplatform'),
    sharpPlatform: localStorage.getItem('sharpplatform') || DEFAULT_PLATFORM,
    sharpSport: localStorage.getItem('sharpsport') || DEFAULT_SPORT,
    resetError: '',
    userPhoneToken: '',
    userCountryCode: '',
    avatarPreview: '',
    defaultAvatar: '',
    avatarChanged: false,
    avatarChangeFailed: false,
    avatarFailMessage: '',
    avatarFileExceeded: false,
    userTiers: null,
    features: {} as any,
    lastAdTime: localStorage.getItem('lastAdTime') || new Date().toISOString(),
    subscriptionLinks: {
      nbaPass: '',
      supportersPass: ''
    },
    cooldown: 60,
    duration: 5,
    ads: {},
    schema: null,
  }),

  getters: {
    isLoggedIn: (state) => state.loggedIn,
    isPublicUser: () => localStorage.getItem('auth_token') === STATIC_TOKEN,
    client: () => sdk,
    notificatiosCount: (state) => state.notifications.filter(item => item.status !== 'read').length,
    isSorare: (state) => state.user.platforms_accounts?.some(item => item.platform === 1) || false,
    isUC: (state) => state.user.platforms_accounts?.some(item => item.platform as number  === 2) || false,
    currency: () => {
      const currStr = localStorage.getItem('currency');
      const currency = currStr && currStr.length ? JSON.parse(currStr) : CURRENCIES[0];
      return currency;
    },
    platformFilter: (state) => ({
      ...(state.sharpPlatform !== 'all' ? { platform: state.sharpPlatform } : {}),
  }),
    sorareAccount: (state) => {
      const sorarePlatform = state.user.platforms_accounts?.find(item => item.platform === 1);
      return sorarePlatform ? sorarePlatform.id : '';
    },
    ucAccount: (state) => {
      const ucPlatform = state.user.platforms_accounts?.find(item => item.platform as number === 2);
      return ucPlatform ? ucPlatform : '';
    },
    isDefaultAvatar: (state) => {
      const matchesInPreview = state.avatarPreview.match(/\/assets\/([^?]+)/);
      const avPrev = matchesInPreview && matchesInPreview.length >= 2 ? matchesInPreview[1] : '';
      return state.user.avatar === state.defaultAvatar || avPrev === state.defaultAvatar
    },
    isAdFree: (state) => state.loggedIn && state.user && state.user.subscriptions && state.user.subscriptions.ad_free,
  },

  actions: {
    async subNewsletter(email: string) {
      try {
          const response = await axios.post(`${FASTIFY_BASE_URL}/webhooks/newsletter/subscribe`, {
              email: email
          });
          return response; 
      } catch (err) {
          console.warn(err);
          throw err;
      }
  },

    async login(credentials: Credentials, fromRegister = false) {
      await this.client.auth.login(credentials)
        .then((res) => {
          if (res && res.access_token) {
            this.loggedIn = true;
            // this.initPushNotificationsPermission();
            // this.setupPushNotifications();
            this.storePhoneTokenOfTheUser();
            this.resetQuotas();
            this.fetchUser().then(() => {
              const loginPath = fromRegister ? '/' : router.currentRoute.value.query.loginpath.toString();
              const isAdblocker = router.currentRoute.value.query.adblocker;
              if (isAdblocker && this.user && this.user.subscriptions && !this.user.subscriptions.ad_free){
                router.push('/subscription');
              }
              else if (!loginPath) router.push('/');
              else router.push(loginPath as string);
            })
          }
        })
        .catch((err) => useErrorStore().$state = err)
    },

    async register(credentials: User) {
      await axios.post(BASE_URL + '/custom/managers', credentials, {
        headers: {
          'Authorization': 'Bearer ' + STATIC_TOKEN
        }
      }).then((response) => {
        if (response) {
          this.login({
            email: credentials.email,
            password: credentials.password,
          }, true)
        }
      }).catch((err) => {
        useErrorStore().$state.errors = err.response.data.errors
      });
    },

    async deleteComment(id: number){
      await axios.delete(FASTIFY_BASE_URL + '/custom/comments', {
        data:{
          comment: id
        },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        },
      }).catch(err =>console.log(err));
    },
    
    async vote(comment : any, voteType:string) {
      const voteData = {
        comment: comment.id,
        type: voteType,
      };
    console.log('comment',comment)
      const hasReacted =
        (voteType === 'upvote' && comment.reactions.upvotes.reacted) ||
        (voteType === 'downvote' && comment.reactions.downvotes.reacted);
    
      try {
        if (hasReacted) {
          const res = await axios.delete(FASTIFY_BASE_URL + '/custom/comments/react', {
            data: { comment: comment.id },
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
            },
          });
          return res;
        } else {
          const res = await axios.post(FASTIFY_BASE_URL + '/custom/comments/react', voteData, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
            },
          });
          return res;
        }
      } catch (error) {
        useErrorStore().$state.errors = error.response.data.errors;
      }
    },

    async logout() {
      await this.client.auth.logout().then(() => {
        window.location.reload();
      });
    },

    async requestReset(email: string) {
      await axios.post(BASE_URL + '/auth/password/request', {
        email: email,
        reset_url: RESET_URL
      }).then((response) => {
        if (response) {
          // console.log(response);
        }
      }).catch(() => {
        // console.log(err);
        // useErrorStore().$state.errors = err.response.data.errors
      });
    },

    async reset(password: string, token: string) {
      return await axios.post(BASE_URL + '/auth/password/reset', {
        password: password,
        token: token
      });
    },

    async resetPassword(psw: string) {
      this.resetError = '';
      await this.client.transport.patch(BASE_URL + '/users/' + this.user.id, {
        password: psw
      })
        .then((res) => console.log(res))
        .catch((err) => {
          if (err && err.errors && err.errors.length) this.resetError = err.errors[0].message;
        })
        .finally()
    },

    async deleteAccount() {
      await this.client.transport.delete('/custom/managers/me').then(() => {
        this.loggedIn = false;
        this.logout();
      });
    },

    async fetchUser() {
      // await this.client.users.me.read({
      //   fields: '*, role.name'
      // }).then((user: any) => {
      //   this.user = user;
      //   this.getFavoriteTeams();
      //   this.getNotifications();
      //   this.getConfig();
      // }).catch(err => console.warn(err));
      await this.directFetchUser();
    },

    async directFetchUser(updateEditable = false) {
      await this.client.transport.get('/custom/managers/me').then(async res => {
        this.user = res.raw
        await this.getConfig();
        this.getFavoriteTeams();
        this.getNotifications();
        this.refreshUserCards();
        this.refreshUC();
        if (updateEditable) this.editableUser = { ...res.raw };
      }).catch(() => this.logout());
    },

    async refreshUserCards(force = false) {
      if (this.user.platforms_accounts && this.user.platforms_accounts.length) {
        this.refreshingCards = true;
        if(force){
          await this.client.transport.patch('/custom/managers/me/platforms_accounts/' + this.sorareAccount + '/cards/?force=true').finally(() => this.refreshingCards = false);
        }
        else{
          await this.client.transport.patch('/custom/managers/me/platforms_accounts/' + this.sorareAccount + '/cards').finally(() => this.refreshingCards = false);
        }
      }
    },



    async fetchUserCards() {
      await this.client.transport.get('/custom/managers/me/platforms_cards').then(res => {
        this.cards = res.raw;
      })
    },

    async getFavoriteTeams() {
      await this.client.transport.get('/custom/managers/me/favorites', {
        params: {
          filter: {
            collection: 'teams'
          }
        }
      }).then( res => {
        if (res.raw)
          this.favoriteTeams = res.raw;
      })
    },

    async addFavoriteTeam(teamId: number){
      await this.client.transport.post('/custom/managers/me/favorites', { 
        item_id: teamId,
        collection: 'teams' 
      }).then(() => this.getFavoriteTeams());
    },

    async removeFavoriteTeam(teamId: number){
      await this.client.transport.delete('/custom/managers/me/favorites/', {
        item_id: teamId,
        collection: 'teams' 
      }).then(() => this.getFavoriteTeams());
    },

    async fetchQuotas() {
      if (this.isLoggedIn) return; // if changed in the future uuid filter param must be added conditionally
      // const uuid = await this.getUUID();
      // await this.client.transport.get('/custom/managers/'+uuid+'/quota').then((res: any) => {
      //   this.quotas.lineups = res.raw.lineups;
      // })
    },

    async resetQuotas() {
      this.quotas = {
        lineups: {
          maximum: -1,
          remaining: -1
        }
      }
    },

    async setStaticToken() {
      sdk.auth.static(STATIC_TOKEN);
      this.getConfig();
    },

    async getUUID() {
      return (await Device.getId()).identifier;
    },

    async getNotifications() {
      if(!this.isLoggedIn) return;
      this.client.transport.get('/custom/managers/me/notifications').then(res => {
        if (res && res.data) this.notifications = res.data
      });
    },

    async readNotifications() {
      await this.client.transport.patch('/custom/managers/me/notifications/mark-as-read');
      await this.getNotifications();
    },

    async deleteNotification(id: number) {
      await this.client.transport.delete('/custom/managers/me/notifications/' + id);
      await this.getNotifications();
    },

    async deleteNotifications() {
      this.client.transport.delete('/custom/managers/me/notifications').then(() => this.notifications = []);
    },

    async connectSorare(code: string){
      const origin = 'https://playsharper.com';
      return this.client.transport.post('/custom/managers/me/platforms_accounts/sorare', {
        code: code,
        callback_url: `${origin}/callbacks/sorare`
      }).catch(err => JSON.stringify(err))
      // TODO Error handling
    },

    requestConnectSorare(){
      const origin = 'https://playsharper.com';
      window.open(`${process.env.VUE_APP_SORARE_AUTH_URL}?client_id=${process.env.VUE_APP_SORARE_OAUTH_CLIENT_ID}&redirect_uri=${origin}/callbacks/sorare&response_type=code`, '_self');
    },

    async unlinkSorare() {
      return this.client.transport.delete('/custom/managers/me/platforms_accounts/' + this.sorareAccount);
    },

    async connectUC(code: string){
      return this.client.transport.patch(`/custom/managers/me/platforms_accounts/ultimate?account_id=${code}`).catch(err => JSON.stringify(err))
    },

    async getUCaccount(){
      const results = await this.client.transport.get('/custom/managers/me/platforms_accounts').catch(err => JSON.stringify(err))
      for (const result of results.raw) {
        if (result.platform.slug === 'ultimate-champions') {
          return result;
        }
      }
      return null
    },

    async unlinkUC() {
      return this.client.transport.delete('/custom/managers/me/platforms_accounts/' + this.ucAccount.id);
    },


    async refreshUC(force = false) {
      const hasUCPlatform = this.user.platforms_accounts && this.user.platforms_accounts.length ? this.user.platforms_accounts.some(account => account.platform === 2) : false;
      if(hasUCPlatform){
        this.refreshingCards = true
        if(force){
          return this.client.transport.patch(`/custom/managers/me/platforms_accounts/${this.ucAccount.id}/cards?force=true`).finally(() => {this.refreshingCards = false});
        }
        else{
          await this.client.transport.patch('/custom/managers/me/platforms_accounts/' + this.ucAccount.id + '/cards').finally(() => {this.refreshingCards = false});
        }
      }
   
    },

    async initPushNotificationsPermission() {
      await PushNotifications.requestPermissions().then(async result => {
        console.log('Request status: ' + JSON.stringify(result))
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          // await PushNotifications.register();
            await this.setupPushNotifications();
        } else {
          // Show some error
          console.log('error token permissions');
        }
      });
    },

    async setupPushNotifications() {
      // On success, we should be able to receive notifications
      // console.log('-----------TOKE------------- ');
      await PushNotifications.addListener('registration',
        async (token: Token) => {
          // console.log('Push registration success, token: ' + JSON.stringify(token));
          // console.log('Token: ' + JSON.stringify(token.value));
          this.directFetchUser().then(async () => {
            let tokens = this.user.firebase_tokens;
            if (tokens && tokens.length) {
              const index = tokens.indexOf(token.value);
              if (index === -1) tokens.push(token.value);
            } else {
              tokens = [token.value];
            }
            // console.log('------------_TOKEN_----------');
            // console.log(tokens);
            // console.log('------------_TOKEN_----------');
            await this.client.transport.patch('/custom/managers/me', { firebase_tokens: tokens }).then(() => {
              // console.log("Token save db Rez: " + JSON.stringify(res));
            }).catch(() => {
              // console.log("Token save db Error: " + JSON.stringify(error));
            });
          })
          // return token;
        });
      // console.log('-----------TOKE------------- ');
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        () => {
          // console.log('Error on registration: ' + JSON.stringify(error));
          // alert('Error on registration: ' + JSON.stringify(error));
        }
      );
      // console.log('-----------Finished------------- ');

    },


    async storePhoneTokenOfTheUser(){
      this.directFetchUser().then(async () => {
        let tokens = this.user.firebase_tokens;
        if (this.userPhoneToken.length){
          if (tokens && tokens.length) {
            const index = tokens.indexOf(this.userPhoneToken);
            if (index === -1) tokens.push(this.userPhoneToken);
          } else {
            tokens = [this.userPhoneToken];
          }
          await this.client.transport.patch('/custom/managers/me', { firebase_tokens: tokens }).then((res) => {
            console.log("Token save db Rez: " + JSON.stringify(res));
          }).catch((error) => {
            console.log("Token save db Error: " + JSON.stringify(error));
          });
        }
      });
    },

    async updateUser() {
      this.updatingUser = true;
      await this.client.transport.patch('/custom/managers/me', {
        first_name: this.editableUser.first_name,
        last_name: this.editableUser.last_name,
        twitter: this.editableUser.twitter,
        description: this.editableUser.description,
        email_notifications: this.editableUser.email_notifications,
        nickname: this.editableUser.nickname
      })
      .then(() => {
        this.directFetchUser();
        this.userUpdated = true;
      })
      .finally(() => this.updatingUser = false);
    },

    async updateAvatar() {

      const matchesInPreview = this.avatarPreview.match(/\/assets\/([^?]+)/);
      const avPrev = matchesInPreview && matchesInPreview.length >= 2 ? matchesInPreview[1] : '';
      if (avPrev === this.defaultAvatar){
        this.setDefaultAvatar();
        return;
      }

      let file;
      if (isPlatform('capacitor')){
        file = await fetch(this.avatarPreview).then((r) => r.blob());
      }else{
        const fileInput = document.getElementById('avatar-field') as HTMLInputElement;
        file = fileInput.files[0];
      }

      const formData = new FormData();
      formData.append('title', this.user.nickname);
      formData.append('file', file);

      this.updatingUser = true;

      await this.client.transport.post(BASE_URL + '/custom/files/avatar', formData).then(async (response) => {
        if (response && response.data && response.data && response.data.id) {
          this.avatarChanged = true;
          this.avatarPreview = '';
          await this.client.transport.patch('/custom/managers/me/avatar', {
            avatar: response.data.id
          })
            .then(() => {
              this.directFetchUser();
            })
            .catch(() => {
              this.avatarFailMessage = 'There was a problem updating your avatar.';
              this.avatarChangeFailed = true;
            })
            .finally(() => {
              this.updatingUser = false;
            });
        }
      }).catch(() => {
        this.avatarFailMessage = 'There was a problem updating your avatar.';
        this.avatarChangeFailed = true;
        this.updatingUser = false;
      });
    },

    async setDefaultAvatar(){
      this.updatingUser = true;
      await this.client.transport.delete('/custom/managers/me/avatar')
      .then(async () => {
        await this.client.transport.patch('/custom/managers/me/avatar', {
          avatar: this.defaultAvatar.length ? this.defaultAvatar : "62c79a41-9030-4dc2-9c47-711057ffc27b"
        })
        .then(() => {
          this.avatarChanged = true;
          this.avatarPreview = '';
          this.directFetchUser();
        })
        .catch(() => {
          this.avatarFailMessage = 'There was a problem updating your avatar.';
          this.avatarChangeFailed = true;
        })
        .finally(() => {
          this.updatingUser = false;
        });
      })
      .catch(() => {
        this.avatarFailMessage = 'There was a problem updating your avatar.';
        this.avatarChangeFailed = true;
        this.updatingUser = false;
      });
    },

    setAvatarPreview(imageURL: string = null){
      const sz = getFileSize(imageURL);
      if (sz > 4){
        this.avatarFileExceeded = true;
        this.avatarPreview = '';
      }
      else { 
        this.avatarFileExceeded = false;
        this.avatarPreview = imageURL;
      }

    },

    async updateUserWallet(walletAddress: string, callback: any, callbackError: any){
      await this.client.transport.post('/custom/managers/me/wallet', {
        address: walletAddress
      }).then((res) => callback(res))
        .catch((err) => callbackError(err))
    },

    async checkUserWalletPermissions(callbackError: any){
      await this.client.transport.patch('/custom/managers/me/wallet/nfts')
      .then(() => {
        this.directFetchUser();
      })
      .catch((err) => callbackError(err))
    },
    
    async getConfig(){
      const res = await this.client.transport.get('/items/config', {
        headers: {
          'Authorization': 'Bearer ' + STATIC_TOKEN
        }
      });
      
      if(res && res.data && res.data.exchange_rates){ 
        this.rates = res.data.exchange_rates;
      }

      if(res && res.data && res.data.default_avatar){
        this.defaultAvatar = res.data.default_avatar;
      }

      if(res && res.data && res.data.features){
        this.features = res.data.features;
      }

      if (res && res.data && res.data.schedule.pop_up_cooldown){
        this.cooldown = res.data.schedule.pop_up_cooldown;
      }

      if (res && res.data && res.data.schedule.pop_up_duration){
        this.duration = res.data.schedule.pop_up_duration;
      }

      if (res && res.data && res.data.schema){
        this.schema = res.data.schema;
      }

    },
    setSharpPlatform(platform: string, sport: string, updateRoute = true, isRoot = false, url = '') {
      localStorage.setItem('sharpplatform', platform);
      localStorage.setItem('sharpsport', sport);
      const isSamePlatform = this.sharpPlatform == platform;
      this.sharpPlatform = platform;
      this.sharpSport = sport;
    
      if (updateRoute && !isRoot && url) {
        const isGeneralPlatform = platform === 'general';
        const isPlayerAvailabilityUrl = url === '/player-availability';
        
        router.push({
          path: url,
          query: {
            platform: isGeneralPlatform && isPlayerAvailabilityUrl ? 'all' : platform,
            sport: sport
          }
        }).then(() =>{
          if(!isSamePlatform){
            window.location.reload()
          }
        });
      }
    },
    

    async getUserCountry(){
      try {
        const response = await fetch('https://ipwhois.pro/?key=9JEY15HIpZy29JXe&fields=country_code');
        const data = await response.json();
        if (data.country_code) this.userCountryCode = data.country_code;
        return data.country_code || null;
      } catch (error) {
        this.userCountryCode = '';
        return null;
      }
    },

    async getUserTiers(){
      try {
        const res = await this.client.transport.get('/custom/managers/me/levels');
        if(res){ 
          this.userTiers = res.raw;
        }
      }
      catch (err) {
        console.warn(err)
        return []
      }
    },

    async getSubscriptionLink(url: string){
      try {
        const res = await this.client.transport.get(url);
        return res && res.raw && res.raw.url ? res.raw.url : null;
      }
      catch (err) {
        console.warn(err)
        return null;
      }
    },

    timeForAd(){
      const lastTime = new Date(this.lastAdTime);
      const now = new Date();
      if ((now.getTime() - lastTime.getTime()) / 1000 > this.cooldown) {
        this.setLastAdTime(now.toISOString());
        if (this.user.subscriptions && this.user.subscriptions.ad_free) return false;
        return true;
      }
      return false;
    },

    setLastAdTime(date: string){
      this.lastAdTime = date;
      localStorage.setItem('lastAdTime', date);
    },

    async getAllAds(device: string){
      try {
        const response = await axios.get(`${FASTIFY_BASE_URL}/ads/config`, {
          params: {
            device: device
          },
          headers: {
            Authorization: `Bearer ${STATIC_TOKEN}`,
          },
        });
        this.ads = response.data;
      } catch (err) {
        console.warn(err);
        this.ads = {};
      }
    }

  },
});
