<template>
    <slot />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { 
  
} from "@ionic/vue";


export default defineComponent({
  name: "publicLayout",
  components: {
  
  },
  
});
</script>
<style lang="sass">
ion-app.ion-page
  background: radial-gradient(circle, #313B47 0%, #161C24 100%)
</style>