<template>
     <ion-list class="ion-no-padding profile-dropdown" color="dark" mode="md">
          <ion-item color="dark" button @click="goTo('/profile')">
            <ion-icon src="/assets/icons//headerIcons/IoPersonCircle.svg"></ion-icon>
            <ion-text>My Profile</ion-text>
          </ion-item>

          <ion-item color="dark" button @click="goTo('/profile/favorites')">
            <ion-icon src="/assets/icons//headerIcons/IoHeartSharp.svg"></ion-icon>

            <ion-text>Favorite teams</ion-text>
          </ion-item>

          <ion-item color="dark" button @click="logout()">
            <ion-icon src="/assets/icons//headerIcons/IoLogOutSharp.svg"></ion-icon>

            <ion-text>Logout</ion-text>
          </ion-item>
        </ion-list>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IonIcon,IonText,
  IonList,
  IonItem,
} from '@ionic/vue';
import { useAuthStore } from "@/store/useAuth";
import router from "@/router";

export default defineComponent({
    components: {
    IonIcon,IonText,
    IonList,
    IonItem,
  },

  setup(){
    const authStore = useAuthStore();
    const logout = async () => {
      await authStore.logout().catch(() => window.location.reload());
    }

    const goTo = (url: string) => {
      if (url == 'logout') logout();
      else router.replace(url);
    }

    return{
       logout,
       goTo
    }
  }
})
</script>

<style lang="sass" scoped>
.profile-dropdown
    background: transparent
    ion-item
        --padding: 24px
        border-radius: 16px
        color: white
        &:not(:first-of-type)
            margin-top: 16px
        ion-icon
            margin-right: 8px
            width: 24px
            height: 24px
        ion-text
            font-weight: 600
            color: #FFF
            line-height: 140%
</style>