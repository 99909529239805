import { createApp, DirectiveBinding } from 'vue'
import { createPinia } from "pinia";

import { createMetaManager } from "vue-meta";

import App from './App.vue'
import router from './router';

import { IonicVue, isPlatform } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Sorare# stylesheets */
import './theme/theme.scss';
import './theme/global.sass';

// Icon style
import '@ionic/core/css/ionic.bundle.css';

// App layouts
import mainLayout from './layouts/main.vue';
import authLayout from './layouts/auth.vue';
import publicLayout from './layouts/public.vue';

import i18n from './i18n';
import auth from "./plugins/auth";
// import firebase from "firebase/app";
import { VueCookieNext } from 'vue-cookie-next'
import { AnalyticsEvent } from './interfaces/analytics';


const metaManager = createMetaManager();

const initApp = () => {
  app.use(router)
    .use(metaManager)
    .component('auth', authLayout)
    .component('main', mainLayout)
    .component('public', publicLayout);
}

const app = createApp(App);
const screenW = window.innerWidth;
const defaultDesktop = isPlatform("desktop");

app.use(i18n)
  .use(IonicVue, {
    mode: screenW < 992 ? 'ios' : 'md',
    platform: {
      desktop: () => {
        return defaultDesktop || screenW > 991;
      }
    }
  })
  .use(createPinia())
  .use(VueCookieNext)
  .use(auth, initApp)
  .directive('track-click', (el, binding: DirectiveBinding<AnalyticsEvent>) => {
      el.addEventListener('click', (clickEvent) => {
        const event: AnalyticsEvent = binding.value;
        event.action = event.action || 'click';
        event.category = event.category || el.nodeName;
        event.label = event.label || el.innerText || clickEvent.target?.innerText || undefined;
        event.value = event.value || undefined;
        event.non_interaction = event.non_interaction || false;
        event.page_location = router.currentRoute.value.name;

        if (window.gtag) {
          window.gtag('event', event.action, {...event, ...router.currentRoute.value.query});
        }
      });
    })
    .config.errorHandler = (err, vm, info) => {
      const formattedError = `${err.toString()}, Info: ${info}`;

      if (window.gtag) {
        window.gtag('event', 'exception', {'description': formattedError, ...router.currentRoute.value.query});
      }
    };

VueCookieNext.config({ expire: '30d', })

router.isReady().then(() => {
  app.mount('#app');
});

window.dataLayer = window.dataLayer || [];
window.gtag = function (...args: any[]) {
  window.dataLayer.push(args);
};
