<template>
  <ion-content :fullscreen="true" class="">
    <div :class="router.currentRoute.name == 'register' ? 'register' : ''" class="auth-container ion-align-items-center ion-justify-content-center">
      <ion-card color="medium" class="main-card">
        <ion-card-content class="ion-no-padding">
          <ion-row class="ion-no-margin">
            <ion-col :size-md="'12'" size="12" class="ion-no-padding form-col">
              <slot />

            </ion-col>
          
          </ion-row>
        </ion-card-content>
      </ion-card>
    </div>
    <page-footer />

  </ion-content>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  IonContent,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
} from "@ionic/vue";
import PageFooter from '@/components/shared/PageFooter.vue';
import { useRouter } from "vue-router";

export default defineComponent({
  name: "authLayout",
  components: {
    IonContent,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    PageFooter
  },

  setup(){
    const router = ref(useRouter());

    return{
      router
    }
  }

});
</script>

<style lang="sass">
.auth-container
  min-height: calc(100% - 42px)
  display: flex
  margin-right: auto
  margin-left: auto
  max-width: 100%
  width: 480px
  &.register
    width: 960px

.main-card
  flex: 1

  ion-card-content
    -webkit-padding-start: 0
    padding-inline-start: 0
    -webkit-padding-end: 0
    padding-inline-end: 0
    padding-top: 0
    padding-bottom: 0

ion-button
  --border-radius: 8px

a
  text-decoration: none

.form-container
  padding: 30px

  h1
    font-weight: 600
    margin-top: 10px
    margin-bottom: 5px

  .subtitle
    color: #637381

  hr
    margin: 0
  
.logo
  font-size: 38px
  margin-bottom: 10px

.divider
  text-align: center
  position: relative
  margin-bottom: 10px
  &::before
    content: ''
    display: block
    position: absolute
    top: 50%
    top: calc(50% - 1px)
    right: 0
    left: 0
    height: 1px
    background-color: rgba(145,158,171,0.24)

  span
    background-color: var(--ion-color-medium)
    position: relative
    z-index: 2
    padding: 0 5px

.sso-login
  height: 46px
  --border-width: 1px
  --border-color: rgba(145,158,171,0.32)

.scr-field
  height: 100%
  background-color: var(--ion-color-success)
  position: relative
  overflow: hidden
  padding: 0

  ion-row.scr-field-row
    height: 100%
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0

    ion-col:nth-child(odd)
      background-color: rgba(255,255,255,.08)

.image-area
  display: flex
  height: 100%

.inner-slide
  max-width: 80%
.auth-container
  margin-top: -30px
@media screen and (max-width: 767px)
  .form-col
    order: 1
  .hidden-sm-down 
    display: none 
  .auth-container
    margin-top: -30px
</style>