<template>
  <div></div>
</template>
<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "GoogleTagManager",
  components: {},

  setup(){

    const scriptId = 'g-tag-script';
    const scriptExists = document.getElementById(scriptId);

    if (!scriptExists) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-H1C7LC9Y4F';
      script.id = scriptId;
      document.head.appendChild(script);

      const script2 = document.createElement('script');
      script2.textContent = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-H1C7LC9Y4F');

        setTimeout(() => {
          gtag('event', 'adblockTracker', {
            'dimensionIndex': 1,
            'adBlockStatus': (function() {
              var ad = document.createElement('ins');
              ad.className = 'AdSense'; // Name of the ad partner you’re working with.
              ad.style.display = 'block';
              ad.style.position = 'absolute';
              ad.style.top = '-1px';
              ad.style.height = '1px';
              document.body.appendChild(ad);
              var adBlockStatus = !ad.clientHeight;
              document.body.removeChild(ad);
              return adBlockStatus;
            })()
          });
        }, 1000)
      `;      

      document.body.appendChild(script2);
      
    }
  }

});
</script>