<template>
  
  <ion-popover :trigger="'notifications_t_'+route" @didPresent="readNotifications">
    <ion-content color="dark">
      <ion-list 
        class="ion-no-padding"
      >
        <ion-item 
          color="dark" 
          class=""
          button 
          v-for="(notification, index) in authStore.notifications" 
          :key="index"
          @click="deleteItem(notification)"  
        >
          <ion-avatar v-if="notification.picture" class="fit-contain">
            <img :src="notification.picture" :alt="notification.picture" />
          </ion-avatar>
          <ion-label class="ion-text-wrap">
            <p class="notification-content ion-no-margin">{{ notification.body }}</p>
            <p class="notification-date ion-no-margin">
              <ion-text color="gray">{{ localeDayAndTime(notification.date_updated) }}</ion-text>
            </p>
          </ion-label>
        </ion-item>
        <ion-item color="dark" v-if="!authStore.notifications.length">
          <span class="notification-content">You don't have any notification</span>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-popover>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  IonList,
  IonItem,
  IonLabel,
  IonAvatar,
  IonPopover,
  IonContent,
  IonText,
} from "@ionic/vue";
import { localeDayAndTime } from '@/services/date';
import { useAuthStore } from "@/store/useAuth";
import { NotificationItem } from "@/interfaces/notification";

export default defineComponent({
  name: "NotificationsWidget",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonAvatar,
    IonPopover,
    IonContent,
    IonText,
  },

  props: {
    route: { type: String, required: true }
  },

  setup(){
    const authStore = useAuthStore();
    
    const readNotifications = () => {
      if (authStore.notificatiosCount) authStore.readNotifications();
    }

    const openWeb = (url: string) => {
      if (!url) return;
      window.open(url, '_self');
    }

    const deleteItem = (notification: NotificationItem) => {
      authStore.deleteNotification(notification.id).then(() => {
        openWeb(notification.url);
      })
    }

    return {
      openWeb,
      deleteItem,
      localeDayAndTime,
      authStore,
      readNotifications
    }
  }

});
</script>

<style lang="sass" scoped>
ion-list
  background: var(--ion-item-background, var(--ion-color-dark))
  padding-top: 3px
  padding-bottom: 3px

  ion-item
    ion-avatar,
    ion-label
      margin-top: 4px
      margin-bottom: 4px

.notification-content,
.notification-date
  font-size: .75rem

.notification-date
  color: #aaa

ion-avatar
  margin-right: 4px
  width: 30px
  height: 30px
</style>