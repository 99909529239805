<template>
  <div></div>
</template>
<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "HotJar",
  components: {},

  setup() {
    const scriptId = 'hotjar-script';
    const scriptExists = document.getElementById(scriptId);

    if (!scriptExists) {
      const script = document.createElement('script');
      script.async = true;
      script.id = scriptId;

      script.textContent = `
        (function(h,o,t,j,a,r) {
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:3631731,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `;
      document.head.appendChild(script);

    }
  }

});
</script>
