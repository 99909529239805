import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, {
    class: "ion-no-padding profile-dropdown",
    color: "dark",
    mode: "md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, {
        color: "dark",
        button: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goTo('/profile')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, { src: "/assets/icons//headerIcons/IoPersonCircle.svg" }),
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode("My Profile")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, {
        color: "dark",
        button: "",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goTo('/profile/favorites')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, { src: "/assets/icons//headerIcons/IoHeartSharp.svg" }),
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode("Favorite teams")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, {
        color: "dark",
        button: "",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.logout()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, { src: "/assets/icons//headerIcons/IoLogOutSharp.svg" }),
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode("Logout")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}