<template>
    <ion-row class="profile-items">

        <ion-col size="12" class="logout" @click="logout()">
            <ion-text>Logout</ion-text>
            <ion-icon src="/assets/icons//headerIcons/IoLogOutSharp.svg"></ion-icon>
        </ion-col>

        <ion-col size="12" class="single-profile-item" @click="goTo('/profile')">

            <ion-avatar class="header-avatar">
                <img v-if="$auth?.user?.avatar"
                    :alt="$auth.user.avatar"
                    :src="`${BASE_URL}/assets/${$auth.user.avatar}?fit=cover&width=100&height=100&quality=80`" />
                <img v-else src="/assets/images/placeholders/user.svg" alt="user.svg"/>
            </ion-avatar>

            <ion-text>My Profile</ion-text>
            <ion-icon class="arrow-icon" src="/assets/icons/expand_less.svg"></ion-icon>

        </ion-col>

        <ion-col size="12" class="single-profile-item" @click="goTo('/profile/favorites')">
            <ion-icon src="/assets/icons//headerIcons/IoHeartSharp.svg"></ion-icon>
            <ion-text>Favorite teams</ion-text>

            <ion-icon class="arrow-icon" src="/assets/icons/expand_less.svg"></ion-icon>


        </ion-col>
    </ion-row>
</template>

<script lang="ts">
import { IonAvatar, IonText, IonIcon, IonCol, IonRow } from "@ionic/vue";
import { BASE_URL } from '@/constants/api';
import { defineComponent } from "vue";
import { useAuthStore } from "@/store/useAuth";
import router from "@/router";
export default defineComponent({
    components: {
        IonAvatar, IonText, IonIcon, IonCol, IonRow
    },
    setup(props, { emit }) {
        const closeModal = () => {
            emit("close");
        };
        const authStore = useAuthStore();
        const logout = async () => {
            await authStore.logout().catch(() => window.location.reload());
        }

        const goTo = (url: string) => {
            router.replace(url);
            closeModal();
        }

        return {
            BASE_URL,
            authStore,
            goTo,
            logout
        }
    }
})
</script>

<style lang="sass" scoped>
.profile-items
    padding: 24px
    background: #222935
    border-top-left-radius: 16px
    border-top-right-radius: 16px
    .logout
        display: flex
        justify-content: flex-end
        align-items: center
        ion-text
            font-size: 14px
            color: white
            margin-right: 6px
        ion-icon
            width: 24px
            height: 24px
    .single-profile-item
        margin-top: 12px
        padding: 24px
        background-color: rgba(22, 28, 35, 0.60)
        border-radius: 16px
        display: flex
        align-items: center
        ion-text
            font-size: 16px
            margin-left: 10px
        .arrow-icon
            margin-left: auto
            transform: rotate(90deg)
        ion-icon
            &:first-child
                width: 24px
                height: 24px

.header-avatar
    width: 24px
    height: 24px
    object-fit: contain
</style>