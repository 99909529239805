import { isPlatform } from "@ionic/vue";

export const getWindowWidth = () => {
    return window.innerWidth;
}

export const isWeb = isPlatform("desktop") || isPlatform("mobileweb");
export const isMobileWeb = () => isPlatform("mobileweb") && getWindowWidth() < 992;
export const isMobile = () => isPlatform("mobile") && getWindowWidth() < 992;
export const isApp = isPlatform("mobile") && isPlatform("capacitor");
export const isAndroidApp = isPlatform("android") && isApp;
export const isIosApp = isPlatform("ios") && isApp;