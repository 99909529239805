import { defineStore } from "pinia";

interface Error{
  message: string;
}

export const useErrorStore = defineStore("error", {
  state: () => ({
    errors: [] as Error[],
  }),

  getters: {
    errorsHTML: (state) => {
      let html = '';
      for (let i = 0; i < state.errors.length; i++) {
        const err = state.errors[i];
        html += err?.message;
      }

      return html;
    }
  }
});