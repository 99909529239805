import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, {
    fullscreen: true,
    class: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.router.currentRoute.name == 'register' ? 'register' : '', "auth-container ion-align-items-center ion-justify-content-center"])
      }, [
        _createVNode(_component_ion_card, {
          color: "medium",
          class: "main-card"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_content, { class: "ion-no-padding" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, { class: "ion-no-margin" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      "size-md": '12',
                      size: "12",
                      class: "ion-no-padding form-col"
                    }, {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "default")
                      ]),
                      _: 3
                    })
                  ]),
                  _: 3
                })
              ]),
              _: 3
            })
          ]),
          _: 3
        })
      ], 2),
      _createVNode(_component_page_footer)
    ]),
    _: 3
  }))
}