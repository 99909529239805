<template>
    <ion-row class="desktop-tools-wrapper">
        <ion-col class="tools-platforms" cols="6">
        <ion-row v-for="platform in authStore.sharpPlatforms" :key="platform.slug.replace('_', '-')"
        :class="{ 'active': platform.slug.replace('_', '-') == selectedPlatform }" @mouseover="selectPlatform(platform.slug.replace('_', '-'))"
        class="single-platform-tools">
                <ion-img :alt="platform.icon" :src="platform.icon"></ion-img>
                <div style="display:flex;">
                    <ion-badge v-if="authStore.isSorare && platform.slug == 'sorare' && authStore.isLoggedIn"
                        color="success" mode="ios" class="platform-link-badge">
                        <ion-icon src="/assets/icons/link.svg" v-if="authStore.isSorare"></ion-icon>
                    </ion-badge>

                    <ion-badge
                        v-if="authStore.isUC && platform.slug.replace('_', '-') == 'ultimate-champions' && authStore.isLoggedIn"
                        color="success" mode="ios" class="platform-link-badge">
                        <ion-icon src="/assets/icons/link.svg" v-if="authStore.isUC"></ion-icon>
                    </ion-badge>
                    <ion-icon class="arrow-icon" src="/assets/icons/expand_less.svg"></ion-icon>
                </div>
            </ion-row>
        </ion-col>

        <ion-col cols="6" class="platform-col" v-if="selectedPlatform">
            <div v-if="categorizedTools.general.length">

                <ion-col v-for="tool in categorizedTools.general" :key="tool.name" style="padding: 0;">
                    <div v-if="!tool.isConnected" class="tool-item"
                        @click="connectAccount(selectedPlatform)"
                        v-track-click="{ category: 'menu', action:'connect-account', label: selectedPlatform + ' ' + tool.name }">
                        <div class="tool-image">
                            <ion-icon :src="tool.icon"></ion-icon>
                        </div>
                        <span>
                            {{ tool.name }}
                        </span>
                    </div>

                </ion-col>
            </div>
            <ion-row v-if="categorizedTools.football.length" class="tool-main-container">
                <div class="subcategory-tools">
                    <ion-icon src="/assets/icons/IoFootball.svg"></ion-icon>
                    <ion-text>Football</ion-text>
                </div>

                <div class="tool-container">
                    <router-link 
                        :to="`${tool.url}?platform=${selectedPlatform}&sport=football`" 
                        cols="4"
                        v-for="tool in categorizedTools.football" 
                        :key="tool.name" 
                        class="tool-item"
                        v-track-click="{ category: 'menu', action:'select-tool', label: selectedPlatform + '-' + tool.name }"
                    >
                        <div class="tool-image">
                            <ion-icon :src="tool.icon"></ion-icon>
                        </div>
                        <span>
                            {{ tool.name }}
                        </span>
                    </router-link>
                </div>
            </ion-row>
            <ion-row v-if="categorizedTools.basketball.length" class="tool-main-container">
                <div class="subcategory-tools">
                    <ion-icon src="/assets/icons/IoBasketballSharp.svg"></ion-icon>

                    <ion-text>Basketball</ion-text>
                </div>
                <div class="tool-container">
                    <router-link 
                        :to="`${tool.url}?platform=${selectedPlatform}&sport=basketball`"
                        cols="4"
                        v-for="tool in categorizedTools.basketball" 
                        :key="tool.name" 
                        class="tool-item"
                        v-track-click="{ category: 'menu', action:'select-tool', label: selectedPlatform + '-' + tool.name }"
                    >
                        <div class="tool-image">
                            <ion-icon :src="tool.icon"></ion-icon>
                        </div>
                        <span>
                            {{ tool.name }}
                        </span>
                    </router-link>
                </div>
            </ion-row>
        </ion-col>
    </ion-row>
</template>


<script lang="ts">
import { IonCol, IonRow, IonImg, IonText, IonBadge, IonIcon } from "@ionic/vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useAuthStore } from "@/store/useAuth";
import { toolsData } from "@/constants/toolsMenu";
import router from '@/router';
import { ToolData, PlatformsStructure } from "@/interfaces/menuTools";


export default defineComponent({
    components: {
        IonRow, IonCol, IonImg, IonText, IonBadge, IonIcon
    },
    setup() {
        const authStore = useAuthStore();
        const selectedPlatform = ref('');
        const platforms = ref({});

        const createPlatformsStructure = (data: ToolData[]): PlatformsStructure => {
            return data.reduce((acc: PlatformsStructure, { name, platforms, category, icon }) => {
                Object.entries(platforms).forEach(([platformKey, { url }]) => {
                    const slug = platformKey.toLowerCase();
                    if (!acc[slug]) {
                        acc[slug] = { general: [], football: [], basketball: [] };
                    }
                    acc[slug][category.toLowerCase()].push({ name, url, icon });
                });
                return acc;
            }, {});
        };

        platforms.value = createPlatformsStructure(toolsData);

        const selectPlatform = (platformSlug) => {
            selectedPlatform.value = platformSlug.toLowerCase();
        };

        const categorizedTools = computed(() => {
            const selectedData = platforms.value[selectedPlatform.value];
            return selectedData || { general: [], football: [], basketball: [] };
        });

        onMounted(() => {
            if (authStore.isLoggedIn && authStore.isSorare) {
                platforms.value['sorare']['general'][0].isConnected = true
            }
            else {
                platforms.value['sorare']['general'][0].isConnected = false
            }

            if (authStore.isLoggedIn && authStore.isUC) {
                platforms.value['ultimate-champions']['general'][0].isConnected = true
            }
            else {
                platforms.value['ultimate-champions']['general'][0].isConnected = false
            }
        })



        const goTo = (url, query) => {
            const isRootRoute = router.currentRoute.value.name === 'landing' || router.currentRoute.value.name == 'games' || router.currentRoute.value.name == 'articles';
            if (url == '/' || url == '/profile') {
                router.push({ path: url });
            }
            else {
                authStore.setSharpPlatform(query.platform, query.sport, true, isRootRoute, url)
                if (isRootRoute) {
                    if (url == '/player-availability' && query.platform == 'general') {
                        router.push({ path: url, query: { platform: 'all', sport: query.sport } })
                    }
                    else {
                        router.replace({ path: url, query: { 
                            platform: query.platform, 
                            sport: query.sport,
                            ...query.loginpath && { loginpath: query.loginpath }
                        } });
                    }

                }
            }
        }

        const connectAccount = (platform: string) => {
            const query = {
                platform: platform,
                sport: ''
            }
            if (!authStore.isLoggedIn){
                query['loginpath'] = '/profile'
                goTo(`/login`, query);
            }else{
                platform == 'sorare' ? authStore.requestConnectSorare() : goTo('/profile', query);
            }
        }

        return {
            authStore,
            selectedPlatform,
            selectPlatform,
            categorizedTools,
            connectAccount,
        };
    }
})
</script>

<style lang="sass" scoped>
.single-platform-tools
    margin: 0
    padding: 12px
    min-width: 250px
    max-width: 250px
    display: flex
    align-items: center
    justify-content: space-between
    border-radius: 16px
    &.active
        background: #00AB55

    &:hover
        background: #00AB55
        cursor: pointer
    ion-img
        height: 24px
        max-width: 140px
        object-fit: contain
    ion-icon
        transform: rotate(90deg)

    ion-text
        font-size: 16px
        color: #fff
        font-weight: 600
        line-height: 14px

.platform-col 
  padding: 0
  min-width: 360px
  max-width: 360px


.tool-main-container 
  display: flex
  flex-direction: column
  flex-wrap: wrap
  span
    min-height: 25px
    max-height: 25px
    display: flex
    align-items: center
.tool-container
    display: flex
    flex-wrap: wrap


.tool-item 
  display: flex
  min-width: 95px
  max-width: 95px
  min-height: 88px
  max-height: 88px
  background-color: #1A212A
  border-radius: 8px
  padding: 8px
  margin-right: 8px
  margin-bottom: 16px
  font-size: 12px
  cursor: pointer
  display: flex
  flex-direction: column
  justify-content: space-between
  color: var(--ion-color-light)
  &:hover
    background-color: #1a212a47
    text-decoration: none

.tool-image
    width: 40px
    height: 40px
    border-radius: 8px
    background: linear-gradient(225deg, #662D91 0%, #10DFEF 100%)
    display: flex
    justify-content: center
    align-items: center
    ion-icon
        width: 24px
        height: 24px

.platform-link-badge
  font-size: 18px
  line-height: 1
  width: 20px
  height: 20px
  padding: 0
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%
  margin-right: 8px
  transform: rotate(90deg)

.subcategory-tools
    display: flex
    align-items: center
    padding-bottom: 8px
    ion-icon
        width: 24px
        height: 24px
        margin-right: 8px
        
.desktop-tools-wrapper
    margin: 0
    padding: 0
    max-width: 738px
    .tools-platforms
        margin: 0
        padding: 0
        padding-right: 24px
</style>