import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78c289ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  href: "/terms-of-use",
  class: "ion-no-margin footer-link"
}
const _hoisted_2 = {
  key: 0,
  href: "/imprint",
  class: "ion-no-margin footer-link"
}
const _hoisted_3 = {
  key: 0,
  href: "/privacy-policy",
  class: "ion-no-margin footer-link"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createBlock(_component_ion_footer, {
    class: _normalizeClass(["s-footer", {'public-footer': _ctx.isPublicLayout, 'force-show': _ctx.forceShow}])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_ion_row, {
          class: _normalizeClass('ion-no-margin ion-justify-content-'+_ctx.alignment)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, {
              size: "12",
              "size-md": "auto",
              class: "ion-no-margin ion-no-padding"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  mode: "ios",
                  size: "small",
                  fill: "clear",
                  class: "ion-no-margin cky-banner-element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      src: "/assets/icons/cookie.svg",
                      color: "warning"
                    }),
                    _createVNode(_component_ion_text, {
                      color: "gray",
                      class: "s-m-start push-1px"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('footer.cookies')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_col, {
              size: "4",
              "size-md": "auto",
              class: "ion-no-margin ion-no-padding"
            }, {
              default: _withCtx(() => [
                (_ctx.route === 'singlearticle')
                  ? (_openBlock(), _createElementBlock("a", _hoisted_1, [
                      _createVNode(_component_ion_text, { color: "gray" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('footer.terms')), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createBlock(_component_router_link, {
                      key: 1,
                      to: "/terms-of-use",
                      class: "ion-no-margin footer-link"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_text, { color: "gray" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('footer.terms')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            }),
            _createVNode(_component_ion_col, {
              size: "4",
              "size-md": "auto",
              class: "ion-no-margin ion-no-padding"
            }, {
              default: _withCtx(() => [
                (_ctx.route === 'singlearticle')
                  ? (_openBlock(), _createElementBlock("a", _hoisted_2, [
                      _createVNode(_component_ion_text, { color: "gray" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('footer.imprint')), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createBlock(_component_router_link, {
                      key: 1,
                      to: "/imprint",
                      class: "ion-no-margin footer-link"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_text, { color: "gray" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('footer.imprint')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            }),
            _createVNode(_component_ion_col, {
              size: "4",
              "size-md": "auto",
              class: "ion-no-margin ion-no-padding"
            }, {
              default: _withCtx(() => [
                (_ctx.route === 'singlearticle')
                  ? (_openBlock(), _createElementBlock("a", _hoisted_3, [
                      _createVNode(_component_ion_text, { color: "gray" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('footer.privacy')), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createBlock(_component_router_link, {
                      key: 1,
                      to: "/privacy-policy",
                      class: "ion-no-margin footer-link"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_text, { color: "gray" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('footer.privacy')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"])
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}